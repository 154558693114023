import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as componentActions from '../../../../redux/actions/chats'; // trocar pelo redux que criarei no chat
import { Styles } from '../../../../styles/material-styles'
import ChatConversations from './conversations'
import ChatHeader from './header'
import ChatSendBar from './send-bar'
import './styles.css'

function Chat(props) {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 599.9);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 599.9);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (<div className="omnichannel-chat" style={ (!isMobile || (isMobile && !props.chats.issidebaropen)) ? { width: '75%' } : { width: '100%' }}>
    <ChatHeader changeSelect={props.changeSelect} customerid={props.customerid} getChatsByHistory = {props.getChatsByHistory}
    customerList={props.customerList}
    loadingcustomerid={props.loadingcustomerid} 
    statusList={props.statusList}  newStatusValue={props.newStatusValue} openOrCloseEndChatModal={props.openOrCloseEndChatModal} openOrCloseTransferChatModal={props.openOrCloseTransferChatModal} />
    <ChatConversations setLoading={props.setLoading} reSendMessage={props.reSendMessage} />
    <ChatSendBar  phonenumberid={props.phonenumberid} sendMessage={props.sendMessage} sendMessageAttachment={props.sendMessageAttachment}
            setNotificationMessage={props.setNotificationMessage}
            setNotificationVariant={props.setNotificationVariant}
            setOpenNotification={props.setOpenNotification}
            customerid={props.customerid} 
    />
  </div>)
}

Chat.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Chat)))