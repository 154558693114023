export const getAttachmentNameByURL = attachmentUrl => {
  let substring = ""
  
  if(attachmentUrl) {
    const match = attachmentUrl.match(/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}_\w+\.\w+)/);

    if (match)
      substring = match[1]
  }
  return substring
}

export const getAttachmentExtension = attachmentName => {
  let substring = ""
  
  if (attachmentName) {
    const match = attachmentName.match(/\.([a-zA-Z0-9]+)$/);

    if (match)
      substring = match[1]
  }
  return substring
}