import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, IconButton, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import SimpleCheckBoxDynamic from "../../components/checkbox dynamic/check";
import SimpleCheckBox from "../../components/checkbox/check";

import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as Service from '../../services/satisfactionsurveyquestionstype.service.';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import Title from '../../components/title-name';
import SimpleSelect from '../../components/select/simple-dynamic';
import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';

import Box from '@mui/material/Box';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { PapiroConsole } from '../../utils/papiroConsole';
import Divider from '@mui/material/Divider';
import DialogGeneral from '../../components/dialog-general';





class SatisfactionSurveyRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    this.ref = React.createRef();
    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );


    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      name: '',
      active: false,
      //SatisfactionSurveyQuestionsTypeId: 0,
      inputFields: [
        { SatisfactionSurveyQuestionsTypeId: 0, questionName: "", indcSatisfiedServiceIndex: false },

      ]

    };
    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      satisfactionSurveyQuestionTypeList: [],
      DialogActivateState: false


    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.openOrCloseActivateModal = this.openOrCloseActivateModal.bind(this);
    this.changeValuesSurveyQuestions = this.changeValuesSurveyQuestions.bind(this);


  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {

    this.getSatisfactionSurveyQuestionsType()
  };


  async getSatisfactionSurveyQuestionsType() {
    this.setState({ loading: true });
    var result = await Service.getAll();
    if (result.success) {
      this.setState({ loading: false, satisfactionSurveyQuestionTypeList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };


  openOrCloseActivateModal = (e) => {
    PapiroConsole.log(e)
    if (e != null) {
      e.preventDefault()
    }

    this.setState({ DialogActivateState: !this.state.DialogActivateState })


  }

  inputFieldValidation() {

    let inputFieldValidation = true
    let iterator = 0;

    if (this.state.item.name == '' || this.state.item.name == null || this.state.item.name == undefined) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "O campo nome precisa ser preenchido"

      });
      return false;
    }
    if (this.state.item.inputFields.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: `É necessario ao menos uma pergunta de Pesquisa de satisfação `

      });
      return false;
    }
    this.state.item.inputFields.forEach((item, index) => {

      if (item.questionName == '' || item.questionName == null || item.questionName == undefined) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: `O campo Nome da pergunta precisa ser preenchido na Pergunta da pesquisa de satisfação ${index + 1}`

        });

        inputFieldValidation = false;
        
      }

      if (item.SatisfactionSurveyQuestionsTypeId == 0) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: `O campo Tipo da pergunta precisa ser preenchido na Pergunta da pesquisa de satisfação ${index + 1}`

        });
        inputFieldValidation = false;
        
      }
      
      if (item.indcSatisfiedServiceIndex == true) {
        iterator++;
        // caso a pergunta seja do tipo sim ou não e seja Indicador de medição de indice de satisfação
        if(item.SatisfactionSurveyQuestionsTypeId == 1 ){
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: `Uma pergunta do tipo "Sim / Não", não pode ser Indicador de medição de indice de satisfação `
  
          });
          inputFieldValidation = false;
          return false;

        }
      }

    });

    if(inputFieldValidation == false){
      return false;
    }

    if (iterator > 1) {
      
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: `Não pode haver mais de uma Pergunta da pesquisa de satisfação com a opção "Indicador de medição de indice de satisfação" ativado.`

      });
      return false;
    }
    if(iterator == 0 ){
      
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: `É necessário que ao menos uma pergunta da pesquisa de satisfação esteja com o "Indicador de medição de indice de satisfação" marcado `

      });
      return false;
    }

    return inputFieldValidation;

  }



  handleSubmit = async e => {
    if (e != null) {

      e.preventDefault();
    }
    const { userSession } = this.props;
    this.setState({ loading: true });
    let data = new FormData();
    let inputFieldValidation = true;
    // validações
    inputFieldValidation = this.inputFieldValidation();

    if (inputFieldValidation) {
      for (let key in this.state.item) {

        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if (key.toString().toLowerCase().indexOf('inputfields') > -1) {
          this.state.item.inputFields.forEach((item, index) => {
            data.append(`QuestionsSelected[${index}].QuestionName`, item.questionName);
            data.append(`QuestionsSelected[${index}].IndcSatisfiedServiceIndex`, item.indcSatisfiedServiceIndex);
            data.append(`QuestionsSelected[${index}].SatisfactionSurveyQuestionsTypeId`, item.SatisfactionSurveyQuestionsTypeId);
          });
        }
        else {
          data.append(key, this.state.item[key]);
        }
      }
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      Api.post('/satisfactionsurvey', data, config)

        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "add.success" }),
              //item: this.initialState,
              // initial state, não tava funcionando para os inputs, então fiz manualmente
              item: {
                createdUser: userSession.user.id,
                createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
                name: '',
                active: false,
                inputFields: [
                  { SatisfactionSurveyQuestionsTypeId: 0, questionName: "", indcSatisfiedServiceIndex: false },
                ]
              }

            });
            // caso o modal de ativação esteja aberto fechar 
            /*if (this.state.DialogActivateState) {

              this.openOrCloseActivateModal()
            }*/

          }
          else {

            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
            Api.kickoff(result);

          }
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
          }
        });
    }



  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async changeValues(stateName, value, text = "") {

    PapiroConsole.log(stateName)
    PapiroConsole.log(value)
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));


  }

  changeValuesSurveyQuestions(event, index, stateName) {

    PapiroConsole.log("index")
    PapiroConsole.log(index)
    PapiroConsole.log("event")
    PapiroConsole.log(event)
    PapiroConsole.log("stateName")
    PapiroConsole.log(stateName)
    if (event.target.name == "indcSatisfiedServiceIndex") {

      const values = [...this.state.item.inputFields];
      values[index][event.target.name] = event.target.checked;
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          inputFields: values
        }
      }));
    }
    else {
      const values = [...this.state.item.inputFields];
      values[index][event.target.name] = event.target.value;
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          inputFields: values
        }
      }));

    }


  };

  handleAddFields = () => {
    
   


   
   
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        inputFields: [
          ...this.state.item.inputFields,
          { SatisfactionSurveyQuestionsTypeId: 0, questionName: "", indcSatisfiedServiceIndex: false },
        ],
      }
    }), () => {
      // Esta função será chamada após o estado ter sido atualizado
      this.scrollToBottom();
    });

      //this.scrollToBottom()

  };

  scrollToBottom = () => {
    
    //this.ref.current.scrollIntoView({ behavior: 'smooth' });
    //window.scrollTo(0, document.documentElement.scrollHeight);
    const element = document.documentElement; // ou qualquer outro elemento que você deseja rolar para
    element.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  handleRemoveFields = (index) => {
    const values = [...this.state.item.inputFields];
    values.splice(index, 1);
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        inputFields: values
      }
    }));
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    PapiroConsole.log(this.state.item)
    PapiroConsole.log("this.state.item")

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root} >
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md' >
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="add.item" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField label={intl.formatMessage({ id: "name" })} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required fullWidth />
                  </Grid>

                  <Grid item xs={11} mt={3}>
                    <Typography variant="h4" component="div">
                      Selecione as perguntas que deseja para sua pesquisa de satisfação
                    </Typography>
                  </Grid>
                  <Grid item xs={1} mt={2}   >
                    {/*<IconButton  style={{borderRadius:'50%', width:'40px', height:'40px'}}   >
                        <AddIcon />
        </IconButton>*/}
                    <Avatar sx={{ bgcolor: "#3f51b4" }} onClick={() => this.handleAddFields()}>
                      <IconButton >
                        <AddIcon style={{ color: 'white' }} />
                      </IconButton>
                    </Avatar>
                  </Grid>
                  <Divider />




                  {item.inputFields.map((inputField, index) => (

                    <Grid item xs={12} sm={12} mt={1}>
                      <Card >
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={11} >
                              <Typography variant="h5" component="div">
                                Pergunta da pesquisa de satisfação {index +1}
                              </Typography>
                            </Grid>
                            <Grid item xs={1} style={{ textAlign: "right" }} >
                              <Avatar sx={{ bgcolor: "#b71c1c" }} onClick={() => this.handleRemoveFields(index)} >
                                <IconButton >
                                  <RemoveIcon style={{ color: 'white' }} />
                                </IconButton>
                              </Avatar>
                            </Grid>




                            <Grid item xs={12} sm={12} >
                              <SimpleSelect label={intl.formatMessage({ id: "satisfaction.survey.question.type" })} options={this.state.satisfactionSurveyQuestionTypeList} stateName='SatisfactionSurveyQuestionsTypeId' name='SatisfactionSurveyQuestionsTypeId' changeSelect={(event) => this.changeValuesSurveyQuestions(event, index)} selected={inputField.SatisfactionSurveyQuestionsTypeId} />
                            </Grid>

                            <Grid item xs={12} sm={12} marginTop={2}>
                              <TextField label={intl.formatMessage({ id: "question.name" })} autoComplete='fquestionname' variant='outlined' name='questionName' onChange={(event) => this.changeValuesSurveyQuestions(event, index)} value={inputField.questionName} required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} marginTop={2}>
                              <SimpleCheckBoxDynamic
                                label={this.props.intl.formatMessage({ id: "indc.satisfied.service" })}
                                name="indcSatisfiedServiceIndex"
                                stateName="indcSatisfiedServiceIndex"
                                changeSelect={(event) => this.changeValuesSurveyQuestions(event, index)}
                                selected={inputField.indcSatisfiedServiceIndex}
                              />
                            </Grid>
                          </Grid>



                        </CardContent>

                      </Card>
                    </Grid>



                  ))}

                  <Grid item xs={12} sm={12} marginTop={2}>
                    <SimpleCheckBox
                      label={this.props.intl.formatMessage({ id: "active" })}
                      name="active"
                      stateName="active"
                      changeSelect={this.changeValues}
                      selected={item.active}
                    />
                  </Grid>


                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}    >
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }} >
                      <Grid item xs={12} sm={4}    >
                        <Button 
                        ref={this.ref}
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>


            {/*<DialogGeneral
              dialogTitle={this.props.intl.formatMessage({ id: "active.satisfaction.survey" })}
              dialogSubTitle={this.props.intl.formatMessage({ id: "active.satisfaction.survey.info" })}
              open={this.state.DialogActivateState}
              openOrCloseModalFunction={this.openOrCloseActivateModal}
              handleConfirmation={this.handleSubmit}
                  />*/}
            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer  />
      </div>
    );
  }
}
SatisfactionSurveyRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(SatisfactionSurveyRegistration)));
