import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as componentActions from '../../../../redux/actions/chats'; // trocar pelo redux que criarei no chat
import { Styles } from '../../../../styles/material-styles'
import { PapiroConsole } from '../../../../utils/papiroConsole'
import ChatMessage from './message'
import './styles.css'

function ChatConversations(props) {
  const { userSession,chats } = props;
  const bottomRef = useRef(null)

  PapiroConsole.log(props.chats)
  PapiroConsole.log(props.chats.chatsv2)
  PapiroConsole.log("props.chatsv2")

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    
    const scrollToTheBottom = () => {
      const scrollEl = bottomRef.current;
      scrollEl?.scroll({
          top: scrollEl?.scrollHeight,
          behavior: 'smooth',
        });
    };
    scrollToTheBottom();

    //alert('ROLAR PARA BAIXO');
  }, [chats])
//}, [props.chats.chatsv2,props.chats.chats])

  

  return (<div className="omnichannel-chat-conversations" ref={bottomRef}>
  { /* props.chats != null && props.chats.messages != null && props.chats.messages.map(message => { */
    props.chats != null && props.chats.chat && props.chats.chat.id && props.chats.chatsv2
    && props.chats.chatsv2[props.chats.chat.id]
     && Object.keys(props.chats.chatsv2[props.chats.chat.id].itens).map((keyName, i) =>  {
    PapiroConsole.log(keyName)
    let message = props.chats.chatsv2[props.chats.chat.id].itens[keyName];
    PapiroConsole.log(message)
    PapiroConsole.log("message aqui new")
    PapiroConsole.log("props.chats.chatsv2")
    PapiroConsole.log(props.chats.chatsv2)

    return <ChatMessage
      userSession={userSession}
      message={message}
      setLoading={props.setLoading} 
      reSendMessage={props.reSendMessage}/>
  })}
  </div>)
}

ChatConversations.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChatConversations)))