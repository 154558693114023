import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../components/select/simple';
import SimpleCheckBox from '../../components/checkbox/check';
import SimpleDatePicker from '../../components/date-pickers/inline';
import * as Service from '../../services/organizationfield.service';
import { parseJSON } from 'date-fns';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage, injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import { WithContext as ReactTags } from 'react-tag-input';
import { DataGrid, GridToolbarFilterButton, GridRenderCellParams } from '@mui/x-data-grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogConfirmation from '../../components/dialog-confirmation'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import * as CalledService from '../../services/called.service';

import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";


import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete';

import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { PapiroConsole } from '../../utils/papiroConsole';
import RenderExpandableCell from '../../components/tooltip/expandable-cell';
import DialogTextEdit from '../../components/dialog-text-edit';

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class OrganizationFieldRegistration extends Component {

    constructor(props) {
        super(props);
        // console.log(props);
        const { userSession, organizations } = this.props;

        this.globalAdm = false;
        this.isClient = true;

        this.fromProps = false
        if (this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
            this.fromProps = true

        if (userSession.user.userrole == null) {
            Api.logoff();
        }


        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype !== 3) {
                this.isClient = false;
            }
            if (item.role.roletype === 0) {
                this.globalAdm = true;
            }
        }
        );

        this.initialState = {
            createdUser: userSession.user.id,
            createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
            OrganizationId: 0,
            OrganizationText: '',
            name: '',
            active: false,
            isrequired: false,
            customeredit: false,
            saveToAllOrganizationsInGroup: false,

            organizationfieldapptarget: 0,
            organizationfieldtype: 0,
            CategoryId: [],

        };

        this.state = {

            CategoryListForDelete: "",
            CategoryListForSelect: [],
            CategoryList: [],
            CategoryListSelect: [],
            DialogTagEdit: false,
            tagEdit: { id: 0, text: "" },
            item: this.initialState,
            organizations: organizations,
            files: [],
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            OrganizationList: [],
            apptargetlist: [{ id: 0, name: 'Todos' }, { id: 1, name: '2DO' }, { id: 2, name: '2MOVE' }],
            fieldTypeList: [{ id: 1, name: 'Texto' }, { id: 2, name: 'Rádio' }, { id: 3, name: 'Checkbox' }, { id: 4, name: 'Seleção' }],
            tags: []
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.categoryDeleteModal = this.categoryDeleteModal.bind(this)
        this.openCategoryInsertModal = this.openCategoryInsertModal.bind(this)
        this.saveCategoryInsertModal = this.saveCategoryInsertModal.bind(this)
        this.setCategoryDelete = this.setCategoryDelete.bind(this)
        this.getOrganizationCategories = this.getOrganizationCategories.bind(this)
        this.closeTagEditDialog = this.closeTagEditDialog.bind(this)
        this.openTagEditDialog = this.openTagEditDialog.bind(this)

    } // Fim - construtor

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        if (this.props.location && /*this.props.location.state.internal === true &&*/ this.props.location.state != undefined) {
            this.getOrganizationsById(this.state.organizations.organization.id);

        }
        else {
            this.getOrganizations();
        }
    };

    categoryDeleteModal = async () => {
        if (this.state.CategoryListForDelete && this.state.CategoryListForDelete.length > 0) {
            const intl = this.props.intl;
            this.setState({ loading: true });

            var categoryList = this.state.CategoryList
            var categoryDeleteList = this.state.CategoryListForDelete.split(",")

            categoryDeleteList.map(itemDelete => {
                if (itemDelete != "") {
                    let returnOk = categoryList.filter(el => el.id != itemDelete)
                    categoryList = returnOk
                }
            })

            this.setState({
                loading: false,
                CategoryList: categoryList,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({ id: "delete.sucess" })
            })

            /*let data = new FormData();
            data.append("organizationcategoriesid", categoryIds);
            data.append("organizationchecklistid", this.state.item.id);
      
            const intl = this.props.intl;
            await Api.post('/users/DeleteOrgOrLocalizationInUser', data)
              .then(result => {
      
                if (result.data.success) {
                  var localizations = [];
      
                  result.data.data.map(item => {
                    item.address.name = `( ${item.address.organization.name} ) ${item.address.name}`;
                    localizations.push(item.address)
                  })
      
                  this.setState({
                    loading: false,
                    OrganizationCategoryList: localizations,
                    openNotification: true,
                    notificationVariant: 'success',
                    notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
                  })
                }
                else {
                  this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      
                  });
                  Api.kickoff(result);
                }
      
              })
              .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                  this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                  });
                }
                else {
                  this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: intl.formatMessage({ id: "process.error" })
                  });
                }
                Api.kickoff(err);
              });*/
        } else {
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: this.props.intl.formatMessage({ id: "select.location" })
            })
        }
    }

    openCategoryInsertModal = async () => {
        this.setState({
            openCategoryInsertModal: !this.state.openCategoryInsertModal
        })
    }

    saveCategoryInsertModal = async () => {

        if (this.state.item.CategoryId != null && this.state.item.CategoryId.length > 0) {

            this.setState({
                loading: true
            })

            const intl = this.props.intl;

            /*let data = new FormData();
        
            var categoryIds = this.state.item.CategoryId.map(item => {
              return item.id + ","
            })
        
            data.append("organizationcategoriesid", categoryIds);
            data.append("organizationchecklistid", this.state.item.id);
        
            const intl = this.props.intl;
            await Api.post('/organizationchecklistorganizationcategory/addinorganizationchecklist', data)
              .then(result => {
                if (result.data.success) {
                  var categories = [];
        
                  result.data.data.map(item => {
                    item.organizationcategory.name = item.organizationcategory.parentname && item.organizationcategory.parentname.length > 0 ? item.organizationcategory.parentname : item.organizationcategory.name;
                    categories.push(item.organizationcategory)
                  })
        
                  this.setState({
                    loading: false,
                    CategoryList: categories,
                    openNotification: true,
                    notificationVariant: 'success',
                    notificationMessage: intl.formatMessage({ id: "add.success" }),
                  })
                  
                }
                else {
                  this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        
                  });
                  Api.kickoff(result);
                }
        
              })
              .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                  this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                  });
                }
                else {
                  this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: intl.formatMessage({ id: "process.error" })
                  });
                }
                Api.kickoff(err);
              });
        
            this.setState({
              openCategoryInsertModal: !this.state.openCategoryInsertModal,
              loading: false
            })*/

            var categories = [];

            this.state.item.CategoryId.map(item => {
                item.name = item.parentname && item.parentname.length > 0 ? item.parentname : item.name;
                categories.push(item)
            })
            PapiroConsole.log("categories")
            PapiroConsole.log(categories)
            this.setState({
                loading: false,
                CategoryList: categories,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({ id: "add.success" }),
                openCategoryInsertModal: !this.state.openCategoryInsertModal,
            })
        }
    }

    setCategoryDelete = async (org) => {
        this.setState({
            CategoryListForDelete: org + "," + this.state.CategoryListForDelete
        })
    }

    handleDelete(i) {
        const { tags } = this.state;
        this.setState({
            tags: tags.filter((tag, index) => index !== i),
        });
    }

    handleAddition(tag) {
        if (tag.text.length < 3) {
            this.setState({
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: 'Mínimo de 3 caracteres'
            })
        } else {
            this.setState(state => ({ tags: [...state.tags, tag] }));
        }
    }

    async getOrganizations() {
        this.setState({ loading: true });
        var result = await Service.getOrganizations();

        if (result.success) {
            this.setState({ loading: false, OrganizationList: result.data });

        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    };

    async getOrganizationsById(id) {

        this.setState({ loading: true });
        var result = await Service.getOrganizationsByIdLight(id);
        if (result.success) {
            this.setState({ loading: false, OrganizationList: [result.data] });

        } else {
            const intl = this.props.intl;

            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });

            Api.kickoff(result);
        }
    };

    closeTagEditDialog() {
        this.setState({ DialogTagEdit: false })
    }

    openTagEditDialog(i) {
        if (i != undefined && i != null && this.state.tags.length > 0 && this.state.tags[i]) {
            const tag = this.state.tags[i]
            this.setState({ tagEdit: { id: tag.organizationfieldoptionsvaluesid, text: tag.text }, DialogTagEdit: true })
        }
    }

    handleTagEdit = (id, value) => {
        const intl = this.props.intl;

        if (value.length < 3) {
            this.setState({
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "trhee.characteres.minimum" })
            })
        } else {
            const tags = this.state.tags.map(t => {
                if (id === t.organizationfieldoptionsvaluesid) {
                    return { ...t, text: value }
                } else {
                    return t
                }
            })
            this.setState({ tags: tags })
            this.setState({ DialogTagEdit: false })
        }
    }

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [name]: value
            }
        }));
    };
    checkValidations() {

        const intl = this.props.intl;

        if (this.state.item.OrganizationId === 0 || this.state.item.name.trim() === "") {


            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "organizationfields.required" }),

            });

            return false

        }
        if (this.state.item.organizationfieldtype === 0) {

            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "organizationfieldtype.required" }),

            });

            return false

        }

        if (this.state.item.organizationfieldtype === 2 || this.state.item.organizationfieldtype === 4) {


            if (this.state.tags.length <= 0) {

                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: intl.formatMessage({ id: "organization.fieldoptionvalues.required" }),

                });

                return false

            }



        }

        return true

    }

    handleSubmit = async e => {
        e.preventDefault();

        this.setState({ loading: true });

        let data = new FormData();

        // console.log(this.state.item)


        var checkValidations = this.checkValidations();

        PapiroConsole.log(checkValidations)
        PapiroConsole.log("checkValidations")





        if (checkValidations) {
            if (this.isClient) {
                this.setState(prevState => ({
                    item: {
                        ...prevState.item,
                    }
                }));
            } else {
                this.setState(prevState => ({
                    item: {
                        ...prevState.item,
                    }
                }));
            }

            let idsStringCategory = "";
            if (this.state.CategoryList.length > 0) {
                this.state.CategoryList.forEach(category => idsStringCategory += `${category.id},`)
                idsStringCategory = idsStringCategory.slice(0, -1);
            }
            /*this.state.CategoryList.forEach(element => {

                data.append("OrganizationCategories", element);
                
            });*/

            //data.append("OrganizationCategories", this.state.CategoryList);
            //PapiroConsole.log(idsStringCategory)
            PapiroConsole.log(this.state.CategoryList)
            PapiroConsole.log("idsStringCategory")


            data.append("OrganizationCategoriesId", idsStringCategory);

            for (let key in this.state.item) {
                if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date') > -1 || key.toString().toLowerCase().indexOf('eventdate') > -1) {
                    data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
                }

                else if (key.toString().toLowerCase().indexOf('organizationfieldapptarget') > -1) {
                    data.append(key, this.state.item[key]);
                }

                else {
                    data.append(key, this.state.item[key]);
                }
            }
            var word = ""
            for (let key in this.state.tags) {
                word = this.state.tags[key].text + "," + word
            }
            data.append("words", word);

            var word = ""
            for (let key in this.state.tags) {
                word = this.state.tags[key].text + "," + word
            }
            data.append("words", word);
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            //console.log(parseJSON(data));
            //console.log(data);
            //console.log(JSON.stringify(data));


            Api.post('/organizationfields', data, config)

                .then(result => {
                    if (result.data.success) {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'success',
                            notificationMessage: intl.formatMessage({ id: "add.success" }),
                            item: this.initialState,
                            files: [],
                            CategoryList: [],
                            CategoryListForSelect: [],
                            tags: []
                        });



                    }
                    else {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

                        });
                        Api.kickoff(result);
                    }

                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.errors) {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        });
                    }
                    else {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }) + err.response.data
                        });
                    }
                    Api.kickoff(err);
                });
        }



    };
    async getOrganizationCategories(id) {

        this.setState({ loading: true });

        var result = await CalledService.getCategoriesByOrganizationSimple(id);

        if (result.success) {
            if (this.isTec == false) {
                if (result.data.length < 1 && id.toString().indexOf(",") == -1) {
                    if (this.AdmOrganization) {
                        this.setState({
                            loading: false, openNotification: true, notificationVariant: "error",
                            notificationMessage: this.props.intl.formatMessage({ id: "called.accompaniment.notification.missing.organization.categories" }),
                            notificationLinkMove: "organizationcategories"
                        });
                    } else {
                        this.setState({
                            loading: false, openNotification: true, notificationVariant: "error",
                            notificationMessage: this.props.intl.formatMessage({ id: "called.accompaniment.notification.missing.organization.categories" })
                        });
                    }
                }

            }
            PapiroConsole.log("=== getOrganizationCategories ===")
            PapiroConsole.log(result.data)
            this.setState({ loading: false, OrganizationCategoriesList: result.data, CategoryListForSelect: result.data });
        }
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    changeValues(stateName, value, text = '') {
        this.setState({ loading: true });

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [stateName]: value
            }
        }));

        if (stateName === 'OrganizationId') {
            this.setState(prevState => ({
                item: {
                    ...prevState.item,
                    ['OrganizationText']: text,
                }
            }));
            PapiroConsole.log(value)
            PapiroConsole.log("value aqui")
            this.getOrganizationCategories(value)
        }

        else if (stateName === "CategoryId") {
            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    ["CategoryText"]: text
                }
            }));
        }

        this.setState({ loading: false });

    };

    removeItem(file) {

        let array = [...this.state.files];
        let index = array.indexOf(file)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ files: array });
        }
    }

    render() {
        const { classes, headerMenu, actionsHeaderMenu } = this.props;
        const { item } = this.state;
        const intl = this.props.intl;
        PapiroConsole.log("render aqui")
        PapiroConsole.log(this.state)
        PapiroConsole.log(this.state.item.organizationfieldtype)
        PapiroConsole.log("this.state.item.organizationfieldtype")
        PapiroConsole.log(this.state.tags)





        let campo;
        if (this.state.item.organizationfieldtype == 2 || this.state.item.organizationfieldtype == 4) {
            campo =
                <div>
                    <Grid item xs={12} sm={12}>
                        <FormattedMessage id="registered.options" />
                    </Grid>
                    <Grid item container xs={12} sm={12} spacing={1} >
                        <Grid item xs={12} sm={11.5}>
                            <ReactTags
                                placeholder={intl.formatMessage({ id: "keywords.press.enter" })}
                                tags={this.state.tags}
                                delimiters={delimiters}
                                handleDelete={this.handleDelete}
                                handleAddition={this.handleAddition}
                                handleTagClick={this.openTagEditDialog}
                                inputFieldPosition='top'
                                inputProps={{
                                    style: { marginBottom: 10 }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={0.5} container direction='column' spacing={3}>
                            <Grid item marginTop={2.5}>
                                <Tooltip title={<h5>{intl.formatMessage({ id: "add.option" })}</h5>} >
                                    <HelpIcon />
                                </Tooltip>
                            </Grid>
                            {this.state.tags.length > 0 && <Grid item>
                                <Tooltip title={<h5>{intl.formatMessage({ id: "edit.option" })}</h5>} >
                                    <HelpIcon />
                                </Tooltip>
                            </Grid>}
                        </Grid>
                    </Grid>
                </div>
        }


        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >

                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='md'>
                        <Dialog
                            fullWidth
                            maxWidth="md"
                            open={this.state.openCategoryInsertModal}
                            onClose={this.closeCategoryModal}
                            aria-labelledby="form-dialog-title-org-mat"
                            PaperProps={{
                                style: {
                                    overflow: 'hidden'
                                }
                            }} >
                            <DialogTitle id="form-dialog-title-org-mat">
                                {this.props.intl.formatMessage({ id: "organization.checklist.edit.new.categories" })}
                                <ClearIcon onClick={this.openCategoryInsertModal} style={{ float: 'right', cursor: 'pointer' }} />
                            </DialogTitle>
                            <DialogContent>
                                <Grid item xs={12} sm={12} >
                                    <Grid container spacing={2} style={{ padding: "5px" }}>
                                        <Grid item xs={12} sm={12}>
                                            < SimpleAutoCompleteMultiple
                                                label={this.props.intl.formatMessage({ id: "menu.categories" })}
                                                options={this.state.CategoryListForSelect}
                                                stateName="CategoryId"
                                                changeSelect={this.changeValues}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} style={{ justifyContent: 'flex-end' }}>
                                            <Grid spacing={2} container justify="flex-end" style={{ padding: 15, justifyContent: 'flex-end' }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        type="button"
                                                        onClick={this.openCategoryInsertModal}
                                                        fullWidth >
                                                        {this.props.intl.formatMessage({ id: "cancel" })}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        type='submit'
                                                        fullWidth
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={this.saveCategoryInsertModal}
                                                    >
                                                        {<FormattedMessage id="save" />}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>

                        <DialogTextEdit
                            msgButton={intl.formatMessage({ id: "confirm" })}
                            msg={intl.formatMessage({ id: "update.field.option.value" })}
                            msgDisagree={intl.formatMessage({ id: "cancel" })}
                            text={this.state.tagEdit.text}
                            handleConfirmation={this.handleTagEdit}
                            handleClose={e => this.closeTagEditDialog()}
                            classes={classes}
                            disabled={false}
                            open={this.state.DialogTagEdit}
                            id={this.state.tagEdit.id}
                            label="Nome"
                        />
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button variant='outlined' color='primary' onClick={() => {
                                        this.fromProps ? this.props.history.push(`/${this.props.match.params.lang}/organization-edit`, { organizations: this.props.organizations, propTab: 4 }) : browserHistory.goBack()
                                    }
                                    }>{<FormattedMessage id="back" />}</Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar className={classes.avatar}>
                                <QueuePlayNext />
                            </Avatar>
                            <Typography component='h1' variant='h5'>
                                {<FormattedMessage id="add.item" />}
                                <Title />
                            </Typography>
                            <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleSelect label={intl.formatMessage({ id: "organization" })} options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={item.OrganizationId} required />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "name" })} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleSelect label={intl.formatMessage({ id: "organization.field.type" })} options={this.state.fieldTypeList} stateName='organizationfieldtype' changeSelect={this.changeValues} selected={this.state.item.organizationfieldtype} required />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {campo}
                                    </Grid>



                                    <Grid item xs={12} sm={12}>
                                        <SimpleSelect label={intl.formatMessage({ id: "organizationfield.app.target" })} options={this.state.apptargetlist} stateName='organizationfieldapptarget' changeSelect={this.changeValues} selected={this.state.item.organizationfieldapptarget} required />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Accordion style={{ marginTop: "6px", marginBottom: "10px" }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading}>{intl.formatMessage({ id: "categories" })}*</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2} xs={12} sm={12} >

                                                    <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                                                        <Grid xs={12} sm={4} >

                                                        </Grid>
                                                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                                                            <DialogConfirmation
                                                                msgButton={"Remover categoria"}
                                                                msg={"Você tem certeza que desejar remover esta categoria deste checklist?"}
                                                                msgDisagree={this.props.intl.formatMessage({ id: "cancel" })}
                                                                msgAgree={this.props.intl.formatMessage({ id: "yes" })}
                                                                handleConfirmation={e => this.categoryDeleteModal()}
                                                                classes={classes}
                                                                icon={<DeleteIcon />}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth
                                                                className={classes.button}
                                                                startIcon={<AddIcon />}
                                                                onClick={e => this.openCategoryInsertModal()} >
                                                                {this.props.intl.formatMessage({ id: "add.categories" })}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid xs={12} sm={12}
                                                        style={{
                                                            marginLeft: 5,
                                                            marginBottom: 5,
                                                            border: '3px solid #eee'
                                                        }} >
                                                        <div style={{ height: 350 }}>
                                                            <DataGrid
                                                                style={{ fontSize: 14, color: '#000' }}
                                                                className="DataGridStyle"
                                                                components={{
                                                                    NoRowsOverlay: this.NoDataRows,
                                                                    Toolbar: GridToolbarFilterButton,
                                                                }}
                                                                rows={this.state.CategoryList}
                                                                rowsPerPageOptions={[5]}
                                                                checkboxSelection
                                                                onSelectionModelChange={itm => {
                                                                    if (itm.length > 0) {
                                                                        this.setCategoryDelete(itm);
                                                                    } else {
                                                                        this.setState({
                                                                            CategoryListForDelete: [],
                                                                        })
                                                                    }
                                                                }}
                                                                columns={[
                                                                    {
                                                                        headerName: intl.formatMessage({ id: "menu.categories" }), field: 'name', width: 500, renderCell: (params) => (
                                                                            <div>
                                                                                <Tooltip title={<h5>{params.row.name}</h5>} >
                                                                                    <span className="table-cell-trucate">{params.row.name}</span>
                                                                                </Tooltip>

                                                                            </div>)
                                                                    },
                                                                ]}
                                                                HeadersVisibility="None"
                                                            />
                                                        </div>
                                                    </Grid>
                                                </Grid>

                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <SimpleCheckBox label={intl.formatMessage({ id: "required" })} name='isrequired' stateName='isrequired' changeSelect={this.changeValues} selected={item.isrequired} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleCheckBox label={intl.formatMessage({ id: "customer.edit" })} name='customeredit' stateName='customeredit' changeSelect={this.changeValues} selected={item.customeredit} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleCheckBox label={intl.formatMessage({ id: "active" })} name='active' stateName='active' changeSelect={this.changeValues} selected={item.active} />
                                    </Grid>
                                    <Grid item xs={11} sm={11}>
                                        <SimpleCheckBox
                                            label={intl.formatMessage({ id: "save.to.all.organizations.in.group" })}
                                            name='saveToAllOrganizationsInGroup'
                                            stateName='saveToAllOrganizationsInGroup'
                                            changeSelect={this.changeValues}
                                            selected={item.saveToAllOrganizationsInGroup} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                        <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                                            <Grid item xs={12} sm={4}    >
                                                <Button
                                                    type='submit'
                                                    fullWidth
                                                    variant='contained'
                                                    color='primary'
                                                    className={classes.submit}
                                                >
                                                    {<FormattedMessage id="save" />}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>

                        </div>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>

                </main>
            </div>
        )
    }
}

OrganizationFieldRegistration.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(OrganizationFieldRegistration)));

