import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Dialog, CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import * as componentActions from '../../redux/actions/phoneregistrationrequests';
import Api2Talk from "../../services/api2talk";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Search from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import SimpleSelect from '../../components/select/simple-search';
import moment from "moment";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Pagination from '@mui/material/Pagination';

class PhoneRegistrationRequests extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;

        const intl = this.props.intl;

        let filters = [
            { name: intl.formatMessage({ id: "organization" }), id: "organization" },
            { name: 'Status', id: "status" },
            { name: 'Data da solicitação', id: "requestDate" },
        ];

        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            userSelected: null,
            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: '',
            filterSelected: 0,
            filters: filters,
            user: null,
            userList: [],
            organizationList: [],
            openDetail: false,
            detail: null
        };

        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);

        this.closeNotification = this.closeNotification.bind(this);
        this.clearFields = this.clearFields.bind(this);
    }

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        this.getItens(page);
    }

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;



                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    onFieldChange(event) {
        this.setState({ searchValue: event.target.value, page: 1 });
    }

    callSearch() {
        if (this.state.searchValue != '') {
            this.getItens(this.state.page);
        }
    }

    async clearFields() {
        await this.setState({ searchValue: '', page: 1, filterSelected: 0 })
        this.getItens(1);
    }

    changeValues = (stateName, value, text = '') => {
        this.setState({
            filterSelected: value
        });
    }

    componentDidMount() {
        this.getItens(1);
    }

    getItens(page = 1) {

        let url = `/registrationphonenumber/paginate?page=${page}`;

        if (this.state.searchValue != '' && this.state.filterSelected) {

            url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`
        }
        if(Api2Talk == null){
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "Api de mensageria não configurada."
            });
            return;
        }

        this.setState({ loading: true });

        Api2Talk.get(url)
            .then(result => {
                if (result.data.success) {
                    let count = result.data.count;
                    this.setState({ count: count });
                    this.setPagination(count, page);

                    const formattedList = result.data.data.map(item => {
                        return {
                            ...item,
                            OrganizationName: item.organization?.name,
                            StatusName: item.requeststatus.name,
                            RequestDate: moment(item.createdat).format('DD/MM/YYYY HH:mm'),
                            PhoneNumber: item.phonenumber,
                        }
                    });
                    this.setState({ loading: false, resultList: formattedList });
                } else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    getDetails(id) {

        this.setState({ loading: true });
        if(Api2Talk == null){
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "Api de mensageria não configurada."
            });
            return;
        }

        if (Api2Talk == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/calleds`
        }

        Api2Talk.get("/registrationphonenumber/reason/"+id)
            .then(result => {
                if (result.data.success) {
                    
                    this.openDetail();

                    this.setState({ 
                        loading: false,
                        detail: { id: id, reason: result.data.data }
                    });

                } else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    }

    openDetail = () => {
        this.setState({ openDetail: !this.state.openDetail });
    }

    render() {
        const { classes, headerMenu } = this.props;
        const intl = this.props.intl;

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='false'>
                        <CssBaseline />
                        <Grid container className={classes.mb20} spacing={0}>
                            <Button
                                variant='contained'
                                color='secondary'
                                size='large'
                                className={classes.button}
                                startIcon={<AddIcon />}
                                component={Link} {...{ to: `/${this.props.match.params.lang}/phoneregistrationrequests-registration` }}
                            >
                                Solicitar registro de novo número
                            </Button>
                        </Grid>

                        <Dialog fullWidth maxWidth="sm" open={this.state.openDetail} onClose={this.openDetail} aria-labelledby="form-dialog-title">
                            
                                <DialogTitle id="form-dialog-title">
                                    <Typography variant="h4" component="h4">
                                        Detalhes da solicitação
                                    </Typography>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid style={{ textAlign: 'center', marginBottom: '15px', marginTop: '15px' }} item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Id da solicitação"
                                            variant="outlined"
                                            value={this.state.detail?.id}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid style={{ textAlign: 'center', marginBottom: '15px' }} item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Razão"
                                            variant="outlined"
                                            value={this.state.detail?.reason}
                                            multiline
                                            rows={4}
                                            disabled
                                        />
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.openDetail} color="secondary">
                                        {intl.formatMessage({ id: "cancel" })}
                                    </Button>
                                </DialogActions>
                        </Dialog>

                        <Grid container spacing={6} alignItems="center" >
                            <Grid item xs={12} sm={12}>

                                <Card className="background-title-search">
                                    <CardContent>

                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={12} sm={12} md={12} lg={3}>
                                                <Typography variant="h5">
                                                    Solicitações de registro de telefone
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={6} sm={6} md={4} lg={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <SimpleSelect 
                                                    fullWidth 
                                                    label={intl.formatMessage({ id: "field" })} 
                                                    options={this.state.filters} 
                                                    stateName='filter' 
                                                    changeSelect={this.changeValues} 
                                                    selected={this.state.filterSelected} 
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={4} lg={3}>
                                                <TextField 
                                                    type="text"
                                                    name="searchValue"
                                                    fullWidth
                                                    value={this.state.searchValue}
                                                    placeholder={intl.formatMessage({ id: "search" })}
                                                    onChange={this.onFieldChange} />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} lg={1}>
                                                <Button
                                                    variant='contained'
                                                    color='secondary'
                                                    size='large'
                                                    className={classes.button}
                                                    style={{ marginRight: -4 }}
                                                    onClick={this.callSearch}
                                                >
                                                    <Search />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} lg={1}>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    size='large'
                                                    onClick={this.clearFields}
                                                    className={classes.button}

                                                >
                                                    <ClearIcon />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>


                            </Grid>

                        </Grid>

                        <MyMaterialTable
                            title=""
                            rowClick={(event, rowData) => {
                                this.props.addCurrent(rowData);
                                this.props.history.push(`/${this.props.match.params.lang}/phoneregistrationrequests-edit`);
                            }}
                            columns={[
                                { title: intl.formatMessage({ id: "id" }), field: 'id' },
                                { title: intl.formatMessage({ id: "organization" }), field: 'OrganizationName' },
                                { title: "Número de telefone", field: 'PhoneNumber' },
                                { title: "Data da solicitação", field: 'RequestDate' },
                                { title: "Status", field: 'StatusName' },
                                { 
                                    title: "Detalhes", 
                                    field: 'action', 
                                    render: rowData => 
                                    
                                        rowData.requeststatusid == 2 && (
                                            <Button 
                                                variant="contained" 
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    this.getDetails(rowData.id);
                                                }} 
                                                color="primary"
                                            >
                                                Detalhes
                                            </Button>
                                    )
                                         
                                },
                            ]}
                            data={this.state.resultList}
                        />

                        <Grid container style={{marginBottom:12,marginTop:8}}>
                            <Grid item xs={12} md={3}>
                                <Pagination  count={this.state.totalPages} page={this.state.page}  onChange={this.onChangePaginate} />
                            </Grid>
                            <Grid item xs={12} sm={3} style={{marginTop:6}}>
                                {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                            </Grid>
                        </Grid>

                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
            </div>
        );
    }
}
PhoneRegistrationRequests.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(PhoneRegistrationRequests)));
PhoneRegistrationRequests.contextType = AbilityContext;
