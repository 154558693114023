import { Box, Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SimpleAutoComplete from "src/components/auto-complete/autocomplete-dialog-with-z-index";

import { getDataRequestById, getMethodAuthIntegration, testAuthentication, testRequestIntegrationService } from "src/services/integrationservice.service";

import ApiResponseDisplay from "src/pages/authentication-edit/components/api-response-display";
import { Overlay } from "src/styles/global";
import styled from 'styled-components';
import "./styles.css";

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const RequestIntegrationService = ({
  token,
  integrationServiceId
}) => {
  const [url, setUrl] = useState("");

  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  // Data auth
  const [methodAuthIntegrationService, setMethodAuthIntegrationService] = useState({id: null, name: ""});
  const [basicAuthUser, setBasicUser] = useState('');
  const [basicAuthPassword, setBasicAuthPassword] =  useState('');
  const [methodAuthList, setMethodAuthList] = useState('');
  const [_token, setToken] = useState('');


  // Data request
  const [params, setParams] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [requestBody, setRequestBody] = useState([]);
  const [jsonValue, setJsonValue] = useState('');
  const [jsonError, setJsonError] = useState('');

  const [showContentRequest, setShowContentRequest] = useState(false);
  const [statusRequest, setStatusRequest] = useState('');
  const [contentRequest, setContentRequest] = useState('');

  // Data service Integration
  const [data, setData] = useState({});

  const getToken = async (id) => {
    try {
      setLoading(true);

      if (id == 0 || id == null) {
        return "";
      } else {
        const formData = {
          integrationauthid: id
        }
  
        var result = await testAuthentication(formData)
  
        if (result && result.data && result.success) {
          try {
            if (result.data.success) {
              const jsonData = JSON.parse(result.data.content);
              
              const accessTokenFieldName = result.data && result.data.accesstokenfieldname ? result.data.accesstokenfieldname : ''
              
              var content = jsonData.data && jsonData.data[accessTokenFieldName] != null ? jsonData.data[accessTokenFieldName] : jsonData[accessTokenFieldName] != null ? jsonData[accessTokenFieldName] : result.data.content
  
              return content;
            }
            else {
              return "";
            }
          } catch (error) {
            return ""
          } finally {
            setLoading(false);
          }
  
        } else {
          return "";
        }
      }
    } catch (err) {
      return "";
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const loadData = async () => {
      const _methodAuthIntegration = await getMethodAuthIntegration();
      const methodAuthIntegrationList = _methodAuthIntegration?.data?.authenticationmethodintegration ?? [];
      const _data = await getDataRequestById(integrationServiceId);

      setMethodAuthList(methodAuthIntegrationList);
      setData(_data);
      setUrl(_data?.url);
      setMethodAuthIntegrationService(methodAuthIntegrationList.find(item => item.id === _data?.methodauth) ?? {id: null, name: ""});

      if (token) {
        setToken(token);
      } else if (_data?.methodauth && _data?.methodauth === 1 && _data?.integrationauthid) {
        const fetchToken = await getToken(_data?.integrationauthid);
        setToken(fetchToken);
      }
    }

    loadData();
  }, [integrationServiceId, token]);

  // Functions
  const shouldShowBlockParameters = useCallback(() => data?.integrationserviceparams && data?.integrationserviceparams?.length > 0, [data]);
  const shouldShowBlockHeaders = useCallback(() => data?.integrationserviceheaders && data?.integrationserviceheaders?.length > 0, [data]);
  const shouldShowBlockRequestBody = useCallback(() => data?.requestbodytype && data?.requestbodytype !== 1, [data]);
  const shouldShowBlockMethodAuth = useCallback(() => data?.methodauth && (data?.methodauth === 1 || data?.methodauth === 2), [data]);

  const buildUrl = (paramsList) => {
    let url = data?.url;
    const queryParams = [];

    paramsList.forEach(param => {
      if (param?.value) {
        if (param.ispathparam) {
          url = url.replace(`{${param.name}}`, encodeURIComponent(param.value));
        } else {
          queryParams.push(`${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`);
        }
      }
    });

    if (queryParams.length > 0) {
      url += '?' + queryParams.join('&');
    }

    setUrl(url);
  }

  const onValidateJson = (value) => {
    try {
      JSON.parse(value);
      setJsonError('');
    } catch (error) {
      setJsonError('JSON inválido');
    }
  };

  const handleChangeValueJson = (e) => {
    const value = e.target.value;
    setJsonValue(value);
    onValidateJson(value);
  };

  const updateValue = (id, value, state, setState, dataSource, isParams = false) => {
    const itemIndex = state.findIndex(item => item?.id === id);
    let newItems = [];

    if (itemIndex !== -1) {
      const updatedList = [...state];
      updatedList[itemIndex] = { ...updatedList[itemIndex], value };
      newItems = updatedList
      setState(updatedList);
    } else {
      const newItem = dataSource?.find(item => item?.id === id);
      if (newItem) {
        newItems = [...state, { ...newItem, value }]
        setState(prev => ([...prev, { ...newItem, value }]));
      }
    }

    if (isParams) {
      buildUrl(newItems)
    }
  };

  const handleChangeParams = (id, value) => updateValue(id, value, params, setParams, data?.integrationserviceparams, true); 
  const handleChangeHeaders = (id, value) => updateValue(id, value, headers, setHeaders, data?.integrationserviceheaders);
  const handleChangeRequestBody = (id, value) => updateValue(id, value, requestBody, setRequestBody, data?.integrationservicerequestbody);

  const getValue = (id, state) => state?.find(item => item?.id === id)?.value ?? "";

  const onGetParamValue = (id) => getValue(id, params);
  const onGetHeaderValue = (id) => getValue(id, headers);
  const onGetRequestBodyValue = (id) => getValue(id, requestBody);

  const handleSendRequest = async (e) => {
    e.preventDefault();
    setContentRequest(false);
    setLoading(true);

    try {

      const payload = {
        url,
        method: data?.method,
        methodAuth: methodAuthIntegrationService?.id ?? null,
        token: _token,
        basicAuthUser,
        basicAuthPassword,
        headers,
        requestbodytype: data?.requestbodytype,
        requestBody,
        requestBodyJson: jsonValue
      }

      const result = await testRequestIntegrationService(payload);

      var content = JSON.stringify(JSON.parse(result?.data?.content), null, 2);
      const statusCode = `Status Code: ${result.data.statuscode}`;

      setStatusRequest(statusCode)
      setContentRequest(content);
      setShowContentRequest(true);

    } catch (err) {
      console.log("ERROR: ", err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Box className="box">
        <Typography component='h6' variant='h6' style={{ marginTop: '8px' }}>
          Url da requisição
        </Typography>

        <Box>
          <p>{url}</p>
        </Box>
      </Box>

      {shouldShowBlockParameters() && (
        <Box className="box">
          <Typography component='h6' variant='h6' style={{ marginTop: '8px' }}>
            Parâmetros da requisição
          </Typography>

          <Box>
            {data?.integrationserviceparams?.map(param => (
              <Box className="box-form-param" key={param?.id}>
                <TextField 
                  inputProps={{ maxLength: 255 }} 
                  fullWidth 
                  label="Nome" 
                  autoComplete='fname' 
                  variant='outlined' 
                  name={param?.name}
                  value={param?.name} 
                  disabled
                />

                <TextField 
                  inputProps={{ maxLength: 255 }} 
                  fullWidth 
                  label="Valor" 
                  autoComplete='fname' 
                  variant='outlined' 
                  name='objectName' 
                  onChange={(e) => handleChangeParams(param?.id, e.target.value)} 
                  value={onGetParamValue(param?.id)}
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {shouldShowBlockHeaders() && (
        <Box className="box">
          <Typography component='h6' variant='h6' style={{ marginTop: '8px' }}>
            Cabeçalho da requisição
          </Typography>

          <Box>
            {data?.integrationserviceheaders?.map(param => (
              <Box className="box-form-param" key={param?.id}>
                <TextField 
                  inputProps={{ maxLength: 255 }} 
                  fullWidth 
                  label="Nome" 
                  autoComplete='fname' 
                  variant='outlined' 
                  name={param?.name}
                  value={param?.name} 
                  disabled
                />

                <TextField 
                  inputProps={{ maxLength: 255 }} 
                  fullWidth 
                  label="Valor" 
                  autoComplete='fname' 
                  variant='outlined' 
                  name='objectName' 
                  onChange={(e) => handleChangeHeaders(param?.id, e.target.value)}
                  value={onGetHeaderValue(param?.id)}
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {shouldShowBlockRequestBody() && (
        <Box className="box">
          <Typography component='h6' variant='h6' style={{ marginTop: '8px' }}>
            Corpo da requisição
          </Typography>

          {data?.requestbodytype === 2 && (
            <Box>
              {data?.integrationservicerequestbody?.map(param => (
                <Box className="box-form-param" key={param?.id}>
                  <TextField 
                    inputProps={{ maxLength: 255 }} 
                    fullWidth 
                    label="Nome" 
                    autoComplete='fname' 
                    variant='outlined' 
                    name={param?.name}
                    value={param?.name} 
                    disabled
                  />

                  <TextField 
                    inputProps={{ maxLength: 255 }} 
                    fullWidth 
                    label="Valor" 
                    autoComplete='fname' 
                    variant='outlined' 
                    name='objectName' 
                    onChange={(e) => handleChangeRequestBody(param?.id, e.target.value)}
                    value={onGetRequestBodyValue(param?.id)}
                  />
                </Box>
              ))}
            </Box>
          )}

          {data?.requestbodytype === 3 && (
            <StyledTextField
              multiline
              rows={10}
              value={jsonValue}
              onChange={handleChangeValueJson}
              placeholder="Digite seu JSON aqui"
              error={!!jsonError} 
              helperText={jsonError} 
            />
          )}
        </Box>
      )}

      {shouldShowBlockMethodAuth() && (
        <Box className="box">
          <Typography component='h6' variant='h6' style={{ marginTop: '8px' }}>
            Método de autenticação
          </Typography>

          <Box>
            <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
              <SimpleAutoComplete 
                label={intl.formatMessage({ id: "authentication.method" })} 
                options={methodAuthList && methodAuthList.length && methodAuthList.length > 0 ? methodAuthList : []} 
                name="methodAuth" 
                stateName='methodAuth' 
                changeSelect={(stateName, value) => setMethodAuthIntegrationService(value)} 
                selected={methodAuthIntegrationService} 
              />
            </Grid>

            {methodAuthIntegrationService.id === 2 &&
              <>
                <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                  <TextField 
                    inputProps={{ maxLength: 255 }} 
                    fullWidth label="Usuário da autenticação básica" 
                    variant='outlined' 
                    name='basicAuthUser' 
                    onChange={(e) => setBasicUser(e.target.value)}
                    value={basicAuthUser} 
                    required={methodAuthIntegrationService.id === 2 ? true : false} />
                </Grid>

                <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                  <TextField 
                    inputProps={{ maxLength: 255 }} 
                    fullWidth label="Senha da autenticação básica" 
                    variant='outlined' 
                    name='basicAuthPassword' 
                    onChange={(e) => setBasicAuthPassword(e.target.value)} 
                    value={basicAuthPassword} 
                    required={methodAuthIntegrationService.id === 2 ? true : false} />
                </Grid>
              </>
            }

            {methodAuthIntegrationService.id === 1 &&
              <>
                <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                  <TextField 
                    fullWidth 
                    label="Token" 
                    multiline
                    rows={10} 
                    variant='outlined' 
                    name='token' 
                    onChange={(e) => setToken(e.target.value)} 
                    value={_token} 
                  />
                </Grid>
              </>
            }
          </Box>
        </Box>
      )}   

      {showContentRequest && (
        <Box className="box">
          <Typography component='h6' variant='h6' style={{ marginTop: '8px' }}>
            Resultado da requisição
          </Typography>

          <Grid item xs={12} sm={12}>
            <ApiResponseDisplay 
              status={statusRequest} 
              content={contentRequest} 
            />
          </Grid>
        </Box>
      )}

      <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
          <Grid item xs={12} sm={4}    >
            <Button
              onClick={handleSendRequest}
              fullWidth
              variant='contained'
              color='primary'
            >
              {<FormattedMessage id="Testar" />}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {loading && (
          <Overlay>
              <CircularProgress color='secondary' />
          </Overlay>
      )}
    </>
  )
}

export default RequestIntegrationService;