import React, { useEffect, useState } from 'react'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import Link from "@mui/material/Link"
import LocationOnIcon from '@mui/icons-material/LocationOn'
import moment from "moment"
import parse from "html-react-parser"
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill' // ES6
import SimpleCheckBox from '../../../components/checkbox/check'
import { renderToStaticMarkup } from "react-dom/server"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withStyles } from '@mui/styles'
import { withRouter } from "react-router-dom"
import { Button, Grid, IconButton, Tooltip } from '@mui/material'
import * as componentActions from '../../../redux/actions/timeentries'
import { Styles } from '../../../styles/material-styles'
import { PapiroConsole } from '../../../utils/papiroConsole'

import Api from '../../../services/api'
import { performanceUpdate } from "../../../services/accompaniment.service";

import './styles.css'

function CalledAction(props) {

  const { addAccompanimentToKnowledgeBase, attachments, calledId, caneditaccompanimentinactivecalled, classes, client, clientStatus, convertHtmlOption, deleteKnowledgeBase, getFile, globalAdm, intl, knowledgebage, openAccompanimentLocationModal, originId, refreshAccompaniments, setLoading, setMessage, showPrivacy, userId } = props;
  const reactQuillModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ]}
  const reactQuillFormats = [
    'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'link', 'image'
  ]

  const [editAccompaniment, setEditAccompaniment] = useState(false)
  const [action, setAction] = useState(props.action.description)
  const [description, setDescription] = useState(props.action.description)

  const handleChangeEditor = (value) => setDescription(value || "")

  const handleShowEditAccompaniment = _ => setEditAccompaniment(true)

  const handleHideEditAccompaniment = _ => {
    setEditAccompaniment(false)
    setDescription(props.action.description)
  }

  const updateAccompaniment = async _ => {
    let sanitizedText = convertHtmlOption(description)
    if (sanitizedText != null && sanitizedText.length > 0) {
      setLoading(true)
      let data = new FormData()
      data.append("Id", props.action.id)
      data.append("CalledId", calledId)
      data.append("Description", description)
      data.append("UserAction", userId)

      let response = await performanceUpdate(data)
      if (response && response.success) {
        setMessage(true, 'success', intl.formatMessage({ id: "edit.success" }))
        setEditAccompaniment(false)
        if (response.data != null)
        {
          setDescription(response.data.description)
        }
        props.refreshAccompaniments()
      } else {
        setLoading(false)
        setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
        Api.kickoff(response)
      }
    }
  }

  const handleFile = (attachment) => {
    getFile(attachment);
  }
  const handleKnowledgeBaseState = async (statename, value) => {
    value ? addAccompanimentToKnowledgeBase() : deleteKnowledgeBase(); 
  }

  const handleOpenAccompanimentLocationModal = () => {
    openAccompanimentLocationModal()
  }

  const configureDescription = _ => {
    let reg = new RegExp(/\@\@{.*\|\@\|.*\}$$/gm)
    let testReg = reg.test(props.action.description.trim())

    if(props.action.description.trim().startsWith("<p> &lt;h") || props.action.description.trim().startsWith("<p>&lt;")) {
      setAction(renderToStaticMarkup(props.action.description))
    } else if(props.action.description.trim().startsWith("<div style=\"") || props.action.description.trim().startsWith("<div dir=") || props.action.description.trim().startsWith("<p>") || props.action.description.trim().startsWith("<h1>") || props.action.description.trim().startsWith("<h2>") || props.action.description.trim().startsWith("<h3>")) {
      setAction(props.action.description)
    } else if(props.action.description.trim().endsWith(".webm") || props.action.description.trim().endsWith(".webm}")) {
      let descriptionSplice = props.action.description.split(" : ");
      if(descriptionSplice.length > 0) {
        if(descriptionSplice[0] && descriptionSplice[1]) {      
          let newDescription = <p> {descriptionSplice[0].replace(props.intl.formatMessage({id:"message.sent"}), props.intl.formatMessage({id:"video.sent"}))} <br/> <video key={'downloadVideo0'} id={'downloadVideo0'} style={{ width: '310px', height: '310px' }} playsInline controls><source src={descriptionSplice[1]} type='video/webm'/></video> </p>
          setAction(renderToStaticMarkup(newDescription))
        }
      }
    } else if(testReg) {
      let descriptionSplice = props.action.description.split(" : ")
      if(descriptionSplice.length > 0) {
        if(descriptionSplice[0] && descriptionSplice[1]) {
          let newAction = descriptionSplice[1].slice(3, -1)
          let newActionSplice = newAction.split("|@|")
          if(newActionSplice.length > 0) {
            if(newActionSplice[0] && newActionSplice[1]) {
              let title = newActionSplice[1]
              if(title.trim().endsWith("}"))
                title = title.slice(0, -1)
							if(props.action.description.trim().endsWith(".pdf") || props.action.description.trim().endsWith(".pdf}")) {	
								let newDescription = <p> {descriptionSplice[0].replace(props.intl.formatMessage({id:"message.sent"}), props.intl.formatMessage({id:"document.sent"}))} <br/> <a href={newActionSplice[0]}  target="_blank"> {title} </a> </p>
								setAction(renderToStaticMarkup(newDescription))
							} else {
								let newDescription = <p> {descriptionSplice[0].replace(props.intl.formatMessage({id:"message.sent"}), props.intl.formatMessage({id:"video.sent"}))} <br/> <a href={newActionSplice[0]}  target="_blank"> {title} </a> </p>
								setAction(renderToStaticMarkup(newDescription))
							}
            }
          }
        }
      }
    } else if(props.action.description.trim().endsWith(".pdf") || props.action.description.trim().endsWith(".pdf}")) {
			let descriptionSplice = props.action.description.split(" : ")
      if(descriptionSplice.length > 0){
        if(descriptionSplice[0] && descriptionSplice[1]){
          let newDescription = <p> {descriptionSplice[0].replace(props.intl.formatMessage({id:"message.sent"}), props.intl.formatMessage({id:"document.sent"}))} <br/> <a href={descriptionSplice[1]}  target="_blank"> {descriptionSplice[1]} </a> </p>
          setAction(renderToStaticMarkup(newDescription))
        }
      }   
    } else if(props.action.description.trim().endsWith(".jpg") || props.action.description.trim().endsWith(".png") || props.action.description.trim().endsWith(".jpeg") || props.action.description.trim().endsWith(".jpg}") || props.action.description.trim().endsWith(".png}") || props.action.description.trim().endsWith(".jpeg}")) {
      let descriptionSplice = props.action.description.split(" : ");
      if(descriptionSplice.length > 0) {
        if(descriptionSplice[0] && descriptionSplice[1]) {
          let newDescription = <p> {descriptionSplice[0]} <br/> <a href={descriptionSplice[1]}  target="_blank"> {descriptionSplice[1]} </a> </p>
          setAction(renderToStaticMarkup(newDescription))
        }
      }    
    }
  }

  useEffect(() => {
    configureDescription()
  }, [])

  useEffect(() => {
    if (props.action != null) {
      if (action != props.action.description)
        configureDescription()
    }
  }, [props.action])

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12}>
        <div className={classes.actionBox}>
          <Grid item xs={12} sm={12}>
            <div className={props.action.privacy ? 'privacyAction' : 'publicAction'}>
              <p>
              {client && props.action.calledrequestingduserid != props.action.useraction.id && props.displayassignedinfomode == 1 && 
                <span className="user-action-name">{props.action.useraction.name}</span>}

              {client && props.action.calledrequestingduserid != props.action.useraction.id && props.displayassignedinfomode == 2 && 
                <span className="user-action-name">{props.techinicalteamname}</span>}

              {client && props.action.calledrequestingduserid == props.action.useraction.id  && 
                <span className="user-action-name">{props.action.useraction.name}</span>}

              {!client && <span className="user-action-name">{props.action.useraction.name}</span>}
                <span className="date">{moment(props.action.actiontime).format("DD/MM/YYYY HH:mm")}</span>
                {showPrivacy && <span className="type">{props.action.privacy ? intl.formatMessage({id:"private.action"}) : intl.formatMessage({id:"public.action"})}</span>}
                { (originId && (originId == 6 || originId == 11)) && (<IconButton style={{ padding: 0 }} aria-label="LocationIcon"><LocationOnIcon onClick={handleOpenAccompanimentLocationModal} style={{ fontColor: "#CC1717", fill: "#CC1717" }} /></IconButton>)}
                { props.action.calledclosed && (<Tooltip title={<h5>{intl.formatMessage({id:"accompaniment.actions.title.recently.added.action"})}</h5>}><IconButton style={{ padding: 0, marginLeft: 10 }} aria-label="CheckIcon"><CheckIcon style={{ fontColor: "#43a047", fill: "#43a047" }} /></IconButton></Tooltip>) }
                {props.action.edited && <span className="date">(Editado)</span> }
                  <div style={{ float: "right", display: "flex" }}>
                    <span style={{ border: "none", marginTop: "-10px"}}>
                      {(!props.action.privacy && !client) && <SimpleCheckBox 
                        label={intl.formatMessage({id:"add.to.knowledgebase"})}
                        name='knowledgebage' 
                        stateName='knowledgebage' 
                        changeSelect={handleKnowledgeBaseState} 
                        selected={knowledgebage} /> }
                    </span>
                    {!editAccompaniment && <span style={{ border: "none"}}>
                      { ((props.caneditaccompanimentinactivecalled && props.clientStatus && props.clientStatus.statustypeid && props.clientStatus.statustypeid == 2) && (globalAdm || (userId == props.action.useraction.id))) && (<Tooltip title={intl.formatMessage({id:"edit.accompaniment"})}><IconButton style={{ padding: 0 }} aria-label="LocationIcon"><EditIcon onClick={handleShowEditAccompaniment} style={{ fontColor: "rgb(39, 122, 254)", fill: "rgb(39, 122, 254)" }} /></IconButton></Tooltip>)}
                  </span>}
                </div>
              </p>
            </div>
          </Grid>
          {!editAccompaniment && <p><div className="action-text-content" style={{ fontWeight: 'normal' }}> { parse (action) } </div></p>}
          {editAccompaniment && (<Grid container spacing={2}>
            <Grid item xs={1} sm={1} md={1} lg={1} />
              <Grid item xs={10} sm={10} md={10} lg={10}>
                <ReactQuill
                  name="description"
                  value={description}
                  style={{ minHeight: 200 }}
                  fullWidth
                  modules={reactQuillModules}
                  formats={reactQuillFormats}
                  required
                  InputLabelProps={{ shrink: description && description.length > 0 ? true : false }}
                  variant="outlined"
                  id="outlined-multiline-static"
                  onChange={handleChangeEditor} />
              </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7}/>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Button variant="contained" fullWidth onClick={handleHideEditAccompaniment} color="secondary">
                    {intl.formatMessage({ id: "cancel" })}
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Button variant="contained" fullWidth onClick={updateAccompaniment} color="primary">
                    {intl.formatMessage({ id: "save" })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>)}
          <div className={classes.attachmentsBox} style={{border: 0}}>
            <ul>
              {attachments.map(item => {
                return (<li key={item.attachment.path}>
                  <AttachFileIcon /> <Link onClick={() => handleFile(item.attachment)}>{item.attachment.description}</Link>
                </li>)
              })}
            </ul>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

CalledAction.propTypes = {
  addAccompanimentToKnowledgeBase: PropTypes.func.isRequired,
  attachments: PropTypes.array,
  calledId: PropTypes.number.isRequired,
  caneditaccompanimentinactivecalled: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  client: PropTypes.bool.isRequired,
  clientStatus: PropTypes.object,
  convertHtmlOption: PropTypes.func.isRequired,
  deleteKnowledgeBase: PropTypes.func.isRequired,
  displayassignedinfomode: PropTypes.bool.isRequired,
  getFile: PropTypes.func.isRequired,
  globalAdm: PropTypes.bool.isRequired,
  knowledgebage: PropTypes.object,
  openAccompanimentLocationModal: PropTypes.func.isRequired,
  originId: PropTypes.number.isRequired,
  refreshAccompaniments: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  showPrivacy: PropTypes.bool.isRequired,
  techinicalteamname: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({
  calleds: state.calleds,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledAction))))