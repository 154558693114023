import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import * as Service from '../../../services/configtwofactorauthentication.service';
import { PapiroConsole } from 'src/utils/papiroConsole';
import Api from '../../../services/api';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import DialogGeneral from '../../../components/dialog-general';
import CircularProgress from '@mui/material/CircularProgress';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import SimpleCheckBox from '../../../components/checkbox/check';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#3f51b4',
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'center',
    borderRight: '1px solid #e0e0e0',
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ConfigTwoFactorAuthentication = (props) => {
  // const [data, setData] = useState([
  //   { role: 'Admin', app: true, email: false },
  //   { role: 'User', app: false, email: true },
  //   // Adicione mais dados conforme necessário
  // ]);

  const [data, setData] = useState([]);
  const [organizationsSelecteds, setOrganizationsSelecteds] = useState([]);
  const [open, setOpen] = useState(false);
  const [openModalOrganization, setOpenModalOrganization] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('');
  const [organizationsList, setOrganizationsList] = useState([]);
  const [selectedOptionsOrganizations, setSelectedOptionsOrganizations] = useState([]);
  const [loadingGrid, setLoadingGrid] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState('2');
  const [twoFactorAuthenticationEnable, setTwoFactorAuthenticationEnable] = useState(null);
  const [twoFactorAuthenticationAppEnable, seTwoFactorAuthenticationAppEnable] = useState(null);

  console.log("data here")
  console.log(data)



  useEffect(() => {

    const intl = props.intl;
    const fetchData = async () => {

      props.setLoading(true)
      setLoadingGrid(true)
      var result = await Service.getDataByConfigId(props.id);
      //console.log(result.data);
      if (result.success) {

        props.setLoading(false)
        setLoadingGrid(false)
        setData(result.data.configtwofactorauthenticationresponse)
        setOrganizationsList(result.data.organizationresponse)
        setTwoFactorAuthenticationEnable(result.data.twofactorauthenticationenable)
        seTwoFactorAuthenticationAppEnable(result.data.twofactorauthenticationappenable)

        var selectedArrayOrganization = []
        if (result.data.organizationconfigtwofactorauthenticationresponse) {
          result.data.organizationconfigtwofactorauthenticationresponse.forEach(element => {
            let found = result.data.organizationresponse.find(org => org.id === element.organizationid);
            if (found) {
              // let object = { channelAdminId: element.id, id: element.userid, name: nameUser }
              //   array.push(object);
              found.organizationconfigtwofactorauthenticationid = element.id
              selectedArrayOrganization.push(found)
            }
          });
        }

        setSelectedOptionsOrganizations(selectedArrayOrganization);


        // setData(result.data.OrganizationConfigTwoFactorAuthenticationResponse)


      } else {
        props.setLoading(false)
        props.setMessage(true, 'error', result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      }

    };

    fetchData();
  }, []);


  const handleAutocompleteChange = (_, newValue) => {
    if (newValue) {
      setSelectedOptionsOrganizations((prevSelectedOptions) => [
        ...prevSelectedOptions,
        newValue,
      ]);
      setAutocompleteInputValue('');
    }
  };


  const handleSwitch = (index, field) => (event) => {
    const newData = [...data];
    newData[index][field] = event.target.checked;
    setData(newData);
  };

  const openOrCloseRemoveModal = () => {
    setOpenModalOrganization(false)
  };



  const handleClickOpen = (index) => () => {
    PapiroConsole.log("index aqui")
    PapiroConsole.log(index)
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentIndex(null);
  };

  

  const filterOptions = (options, { inputValue }) => {
    
    PapiroConsole.log("options")
    PapiroConsole.log("inputValue")
    PapiroConsole.log(options)
    PapiroConsole.log(inputValue)
  
  
    return options.filter((option) => {
      // Verifica se a opção já foi selecionada e se o inputValue corresponde ao label, se existir
      const isNotSelected = !selectedOptionsOrganizations.find((selected) => selected.id === option.id);
      const matchesInput = option.name?.toLowerCase().includes(inputValue.toLowerCase()); // Uso de "?" para evitar erro
  
      return isNotSelected && matchesInput;
    });
  };

  const handleSave = () => {

    const intl = props.intl;
    props.setLoading(true)
    // Lógica para salvar as alterações
    console.log(`Salvando alterações para o índice ${currentIndex}`);
    if (data != null && data.length > 0 && data[currentIndex]) {
      //criação na tabela 
      if (data[currentIndex].id != 0) {
        Api.put('/configtwofactorauthentication', data[currentIndex])

          .then(result => {
            if (result.data.success) {
              props.setLoading(false)
              props.setMessage(true, 'success', 'Registro atualizado com sucesso')
            }
            else {
              props.setLoading(false)
              props.setMessage(true, 'error', result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))

            }
          })
          .catch(err => {
            props.setLoading(false)
            props.setMessage(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

          });
      }
      else {

        PapiroConsole.log("data[currentIndex]")
        PapiroConsole.log(data)
        PapiroConsole.log(data[currentIndex])

        let dataSend = data[currentIndex];
        dataSend.configId = props.id;
        Api.post('/configtwofactorauthentication', dataSend)

          .then(result => {
            PapiroConsole.log("result")
            PapiroConsole.log(result)
            if (result.data.success) {
              props.setLoading(false)
              props.setMessage(true, 'success', 'Registor atualizado com sucesso')
              var newData = data;
              data[currentIndex].id = result.data.data.id
              setData(newData)

            }
            else {
              props.setLoading(false)
              props.setMessage(true, 'error', result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
            }
          })
          .catch(err => {
            if (err.response && err.response.data && err.response.data.errors) {
              props.setLoading(false)
              props.setMessage(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
            }
            else {
              props.setLoading(false)
              props.setMessage(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }) + err.response.data)
            }
          });
      }
    }
    handleClose();
  };

  const handleRemoveOption = (optionToRemove) => {
    if (optionToRemove.organizationconfigtwofactorauthenticationid) {
      // this.setState({ DialogRemoveState: true, IdToDelete: { channelAdminId: optionToRemove.channelAdminId, channelId: this.state.id, userId: optionToRemove.id }, })

      setOpenModalOrganization(true)
      setIdToDelete(optionToRemove.organizationconfigtwofactorauthenticationid)


    }
    else {
      setSelectedOptionsOrganizations((prevSelectedOptions) =>
        prevSelectedOptions.filter((option) => option.id !== optionToRemove.id)
      );

    };

  }


  const handleDeleteOption = async () => {

    const intl = props.intl;
    props.setLoading(true)

    if (idToDelete != null) {
      var result = await Api.delete(`/organizationconfigtwofactorauthentication/${idToDelete}`);

      if (result.data.success) {

        setSelectedOptionsOrganizations((prevSelectedOptions) =>
          prevSelectedOptions.filter((option) => option.organizationconfigtwofactorauthenticationid !== idToDelete)
        );
        setIdToDelete(null)
        openOrCloseRemoveModal()
        props.setLoading(false)
        props.setMessage(true, 'success', 'Registro atualizado com sucesso')


      } else {

        props.setLoading(false)
        props.setMessage(true, 'error', result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        openOrCloseRemoveModal()
      }
    }
    else {
      props.setLoading(false)
      props.setMessage(true, 'error', "Ocorreu um erro ao processar a solicitação")
      openOrCloseRemoveModal()
    }






  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const intl = props.intl;

    props.setLoading(true)

    let data = {
      configId: props.id,
      organizationsids: [],
      twoFactorAuthenticationAppEnable: twoFactorAuthenticationAppEnable,
      twoFactorAuthenticationEnable: twoFactorAuthenticationEnable,
      configId: props.id
    };

    PapiroConsole.log("selectedRadio")
    PapiroConsole.log(selectedRadio)
    var organizationsSelected = []
    if (selectedRadio == "2") {
      organizationsSelected = selectedOptionsOrganizations?.filter(org => !org.organizationconfigtwofactorauthenticationid);
    }
    else if (selectedRadio == "1") {
      organizationsSelected = organizationsList.filter(c => !c.organizationconfigtwofactorauthenticationid);
    }



    if (organizationsSelected && organizationsSelected.length > 0) {
      organizationsSelected.forEach((item, index) => {
        data.organizationsids[index] = item.id;
      });

    }


    Api.post('/organizationconfigtwofactorauthentication', data)
      .then((result) => {
        if (result.data.success) {
          props.setLoading(false)
          props.setMessage(true, 'success', 'Registro atualizado com sucesso')
          if (result.data.data != null && result.data.data.length > 0) {

            var selectedOptions = [];

            if (selectedRadio == "2") {
              selectedOptions = selectedOptionsOrganizations;
            }
            else {
              selectedOptions = [...organizationsList, ...selectedOptionsOrganizations]
            }
            result.data.data.forEach(element => {
              let found = selectedOptions.find(item => item.id === element.organizationid);
              if (found != null) {
                found.organizationconfigtwofactorauthenticationid = element.id
              }
            });
            setSelectedOptionsOrganizations(selectedOptions)





          }

        } else {

          props.setLoading(false)
          props.setMessage(true, 'error', result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))


        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          props.setLoading(false)
          props.setMessage(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        }
        else {
          props.setLoading(false)
          props.setMessage(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }) + err.response.data)
        }
      });

  }

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  const newchangeValues = (stateName, value) => {


  }

  return (
    <Grid container spacing={2} >
      {/* <Grid item xs={12}>
        <Typography style={{ fontWeight: "bold", color: "#848383" }} variant="h6" component="div" gutterBottom>
          Política de autenticação de dois fatores
        </Typography>
      </Grid> */}
      <Grid item xs={12} sm={12}>
        <SimpleCheckBox
          label={props.intl.formatMessage({ id: "twoFactorAuth.enableEmail" })}
          name='twoFactorAuthenticationEnable'
          stateName='twoFactorAuthenticationEnable'
          changeSelect={(stateName, value) => setTwoFactorAuthenticationEnable(value)}
          selected={twoFactorAuthenticationEnable} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <SimpleCheckBox
          label={props.intl.formatMessage({ id: "enable.two-factor.authentication.via.app" })}
          name='twoFactorAuthenticationAppEnable'
          stateName='twoFactorAuthenticationAppEnable'
          changeSelect={(stateName, value) => seTwoFactorAuthenticationAppEnable(value)}
          selected={twoFactorAuthenticationAppEnable} />
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Role</StyledTableCell>
                <StyledTableCell>App</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Ação</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <StyledTableRow key={row.role}>
                  <StyledTableCell component="th" scope="row">
                    {row.rolename}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Switch
                      checked={row.twofactorauthenticationappenable}
                      onChange={handleSwitch(index, 'twofactorauthenticationappenable')}
                      color="primary"
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Switch
                      checked={row.twofactorauthenticationemailenable}
                      onChange={handleSwitch(index, 'twofactorauthenticationemailenable')}
                      color="primary"
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title="Salvar alterações">
                      <IconButton onClick={handleClickOpen(index)} style={{ color: '#3f51b4' }}>
                        <SaveIcon />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>

        <FormControl component="fieldset">
          <FormLabel component="legend">Escolha uma opção</FormLabel>
          <RadioGroup row value={selectedRadio} onChange={handleRadioChange}>
            <FormControlLabel value="1" control={<Radio />} label="Selecionar todas as organizações" />
            <FormControlLabel value="2" control={<Radio />} label="Escolha as organizações" />
          </RadioGroup>
        </FormControl>

      </Grid>




      {selectedRadio == "2" &&
        <Grid item xs={12}>
          <Autocomplete

            inputValue={autocompleteInputValue}
            options={organizationsList}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Organizações" variant="outlined" fullWidth />
            )}
            renderOption={(props, option, state) => (
              <>
                <List>
                  <ListItem {...props}>
                    <ListItemText primary={option.name} />
                  </ListItem>
                  {state.index < organizationsList.length - 1 && <Divider />}
                </List>
              </>
            )}
            onChange={handleAutocompleteChange}
             filterOptions={(options, state) => filterOptions(options, state, selectedOptionsOrganizations)}
            onInputChange={(_, newInputValue) => setAutocompleteInputValue(newInputValue)}
            fullWidth
          />

          <Paper elevation={3} style={{ marginTop: 16 }}>
            {loadingGrid ? (
              <CircularProgress size={40} style={{ margin: '20px auto', display: 'block' }} />
            ) : (
              <TableContainer>
                <Table>

                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold', color: '#666' }}>Nome</TableCell>
                      <TableCell style={{ fontWeight: 'bold', color: '#666' }} >Excluir</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {selectedOptionsOrganizations.map((option, index) => (
                      <TableRow key={index}>
                        <TableCell>{option.name}</TableCell>

                        <TableCell >
                          <IconButton
                            color="secondary"
                            onClick={() => handleRemoveOption(option)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      }


      <Grid container style={{ marginTop: '25px' }}>
        <Grid item xs={4} />
        <Grid item xs={4} />
        <Grid item xs={4} container alignItems="flex-end" justifyContent="flex-end">
          <Button variant="contained" color="primary" type="submit" fullWidth onClick={handleSubmit}>
            Salvar
          </Button>
        </Grid>
      </Grid>





      <DialogGeneral
        dialogTitle={"Remover Organização da autenticação de dois fatores ?"}
        dialogSubTitle={"Você deseja remover esta organização ?"}
        open={openModalOrganization}
        openOrCloseModalFunction={openOrCloseRemoveModal}
        handleConfirmation={handleDeleteOption}
      />


      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirmar Salvar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Você realmente deseja salvar as alterações?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>


  );
};

export default ConfigTwoFactorAuthentication;
