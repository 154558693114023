import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Button, Grid, Radio, Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { withStyles } from "@mui/styles";
import PropTypes from 'prop-types';
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import DialogConfirmation from '../../../../components/dialog-confirmation-not-button';
import DialogGeneral from '../../../../components/dialog-general';
import { AbilityContext } from '../../../../config/ability-context';
import * as ItemService from '../../../../services/2talk/channel';
import * as WebchatChannelService from '../../../../services/2talk/webchat-channel.service';
import Api2Talk from '../../../../services/api2talk';
import { Styles } from "../../../../styles/material-styles";

class WebchatChannel extends Component {

  constructor(props) {
    super(props);
    const { webchats } = this.props;
    this.selectedChannelsArray = [];

    this.state = {
      webchatid: webchats.webchat.id,
      channelList: [],
      autocompleteInputValue: '',
      item: {
        selectedOptions: [],
      },
      DialogRemoveState: false,
      IdToDelete: { webchatChannelId: 0, channelId: 0 },
      loadingGrid: false,
      dialogConfirmationOpen: false,
      idToChangeDefault: 0,
      valueToChangeDefault: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.openOrCloseRemoveModal = this.openOrCloseRemoveModal.bind(this);
    this.handleDeleteOption = this.handleDeleteOption.bind(this);
    this.checkValidation = this.checkValidation.bind(this);
    this.copyCode = this.copyCode.bind(this);
    this.handleChangeDefault = this.handleChangeDefault.bind(this);
    this.closeDialogConfirmation = this.closeDialogConfirmation.bind(this);
    this.openDialogConfirmation = this.openDialogConfirmation.bind(this);
  }

  async componentDidMount() {
    this.setState({ loadingGrid: true });
    await this.getChannelList();
    await this.getChannelsAssociations();
    this.setState({ loadingGrid: false });
  }

  async getChannelList() {
    const { getNotification, setLoadingState } = this.props;
    const response = await ItemService.getChannelList();

    setLoadingState(false)

    if (response.success) {
      const channels = response.data?.map(channel => ({ id: channel.id, name: channel.name, isdefault: false }));
      this.setState({ channelList: channels });
    } else {
      const intl = this.props.intl;
      getNotification(false, true, 'error', response.data && response.data.response && response.data.response.data && response.data.response.data.errors && response.data.response.data.errors[0] ? response.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
    this.setState({ loadingGrid: false });
  }

  checkValidation() {
    const { getNotification } = this.props;

    let newChannels = this.state.item.selectedOptions?.filter(channel => !channel.webchatchannelid) || [];

    if (newChannels.length == 0) {
      getNotification(false, true, 'error', "Você deve adicionar ao menos um novo canal")
      return false;
    }

    return true;
  }

  async handleSubmit(e) {
    e.preventDefault();

    const { getNotification, setLoadingState } = this.props;

    try {

      let validation = this.checkValidation();

      
      if (validation) {
        setLoadingState(true)

        let input = {
          webchatid: this.state.webchatid,
          channelids: this.state.item.selectedOptions.map(channel => channel.channelid)
        };

        if (Api2Talk == null) {
          getNotification(false, true, 'error', "Api de mensageria não configurada.")
          return;
        }

        const output = await WebchatChannelService.create(input);

        setLoadingState(false)

        if (output.success) {
          await this.getChannelsAssociations();
          getNotification(false, true, 'success', "Canais associados com sucesso");
        } else {
          const intl = this.props.intl;
          getNotification(false, true, 'error', output.errors && output.errors[0] ? output.errors[0] : intl.formatMessage({ id: "process.error" }))

        }
      }

    } catch (err) {
      setLoadingState(true)
      const intl = this.props.intl;
      getNotification(false, true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }

  async getChannelsAssociations() {
    const { getNotification } = this.props;
    const intl = this.props.intl;

    try {
      const output = await WebchatChannelService.getChannelsByWebchatId(this.state.webchatid);

      if (output.success) {
        if (output.data && output.data.length > 0) {
          this.updateList(output.data)
        }
      } else {
        getNotification(false, true, 'error', output && output.errors && output.errors[0] ? output.errors[0] : intl.formatMessage({ id: "process.error" }))
      }
    } catch (err) {
      getNotification(false, true, 'error', intl.formatMessage({ id: "process.error" }))
    }
  }

  updateList(list) {
    let array = [];

    list.forEach(element => {
      if (element.id && element.channel) {

        let nameChannel = '';

        if (this.state.channelList.length > 0) {
          let found = this.state.channelList.find(channel => channel.id === element.channel.id);
          nameChannel = found ? found.name : nameChannel;
        }

        let object = {
          channelwebchatid: element.id,
          webchatid: this.state.webchatid,
          channelid: element.channel.id,
          id: element.id,
          name: nameChannel,
          token: element?.token,
          scriptname: element?.scriptname ?? "",
          isdefault: element.isdefault
        }

        array.push(object);
      }
    });

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        selectedOptions: array,
      }
    }));
  }

  handleAutocompleteChange = (_, newValue) => {
    const { getNotification } = this.props;

    if (newValue) {
      const channelAlreadySelected = this.state.item.selectedOptions?.find(channelSelected => channelSelected?.channelid === newValue?.id);

      if (channelAlreadySelected) {
        getNotification(false, true, 'error', "O canal já está associado ao webchat.");
        return;
      } else {
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            selectedOptions: [...prevState.item.selectedOptions, { ...newValue, channelid: newValue?.id }],
          },
          autocompleteInputValue: '',
        }));
      }
    }
  };

  async handleDeleteOption() {
    const { getNotification, setLoadingState } = this.props;
    const intl = this.props.intl;

    if (
      this.state.IdToDelete
      && this.state.IdToDelete.channelwebchatid
      && this.state.IdToDelete.channelwebchatid != 0
    ) {

      this.openOrCloseRemoveModal()
      setLoadingState(true)

      try {
        const output = await WebchatChannelService.deleteByChannelWebchatById(this.state.IdToDelete.channelwebchatid);

        setLoadingState(false)

        if (output.success) {
          this.setState((prevState) => ({
            item: {
              ...prevState.item,
              selectedOptions: prevState.item.selectedOptions.filter((option) => option.channelwebchatid !== this.state.IdToDelete.channelwebchatid),
            },
          }));
          getNotification(false, true, 'success', "Canal deletado com sucesso")
          this.setState({ DialogRemoveState: false, IdToDelete: { channelwebchatid: 0 } });

        } else {
          getNotification(false, true, 'error', output && output.errors && output.errors[0] ? output.errors[0] : intl.formatMessage({ id: "process.error" }))
        }
      } catch (err) {
        setLoadingState(false)
        getNotification(false, true, 'error', intl.formatMessage({ id: "process.error" }))
      }

    }
  }

  handleRemoveOption = (optionToRemove) => {
    if (optionToRemove.channelwebchatid) {
      this.setState({ DialogRemoveState: true, IdToDelete: { channelwebchatid: optionToRemove.channelwebchatid } })
    }
    else {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          selectedOptions: prevState.item.selectedOptions.filter((option) => option.id !== optionToRemove.id),
        },
      }));
    }
  };

  filterOptions = (options, { inputValue }) => {
    const { selectedOptions } = this.state.item;
    return options.filter(
      (option) =>
        !selectedOptions.find((selected) => selected.channelid === option.id) &&
        !this.state.item.selectedOptions.find((selected) => selected.channelid === option.id)
    );
  };

  changeValues = async (stateName, value, text = '') => {
    this.setState({ loading: true });
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
  }

  openOrCloseRemoveModal = () => {
    this.setState({ DialogRemoveState: false })
  }

  copyCode = (token, script) => {
    const { getNotification } = this.props;
    navigator.clipboard.writeText(`<!-- WebChat 2TALK start --> \n <script> \n var _2talk_chatclientkey = "${token}"; \n </script> \n <script src="${script}"></script> \n <!-- WebChat 2TALK end -->`)
    getNotification(false, true, 'success', "Código copiado!")
  }

  handleChangeDefault = async () => {
    const id = this.state.idToChangeDefault
    const value = this.state.valueToChangeDefault
    var options = this.state.item.selectedOptions

    if (value == true) {
      await WebchatChannelService.setDefault(id)
      for (let i = 0; i < options.length; i++) {
        if (options[i].id == id) {
          options[i].isdefault = true
        } else {
          options[i].isdefault = false
        }
      }
    } else {
      for (let i = 0; i < options.length; i++) {
        if (options[i].id == id) {
          options[i].isdefault = false
          break
        }
      }
    }

    this.setState(prevState => ({
      ...prevState,
      item: {
        selectedOptions: options
      },
      dialogConfirmationOpen: false
    }))
  }

  closeDialogConfirmation() {
    this.setState({ dialogConfirmationOpen: false })
  }

  openDialogConfirmation(id, value) {
    this.setState({ dialogConfirmationOpen: true, idToChangeDefault: id, valueToChangeDefault: value })
  }

  render() {

    const { classes, isWebchatDefault, intl } = this.props;
    const { item, channelList, autocompleteInputValue, loadingGrid } = this.state;

    return (

      <div>
        <form name='myForm' className={classes.form} encType='multipart/form-data' onSubmit={this.handleSubmit}>
          <Grid container spacing={2} style={{ marginTop: 15 }}>
            <Grid item xs={12}>
              <Autocomplete
                value={null}
                inputValue={autocompleteInputValue}
                options={channelList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Canais" variant="outlined" fullWidth />
                )}
                renderOption={(props, option, state) => (
                  <>
                    <List>
                      <ListItem {...props}>
                        <ListItemText primary={option.name} />
                      </ListItem>
                      {state.index < channelList.length - 1 && <Divider />}
                    </List>
                  </>
                )}
                onChange={this.handleAutocompleteChange}
                filterOptions={this.filterOptions}
                changeSelect={this.changeValues}
                onInputChange={(_, newInputValue) => this.setState({ autocompleteInputValue: newInputValue })}
                fullWidth
              />

              <div>
                {loadingGrid ? (
                  <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
                ) : (
                  item.selectedOptions.map((option, index) => (
                    <div key={index} style={{ padding: '16px', marginTop: '16px', border: '1px solid #ccc' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                        <b>
                          {option.name}
                        </b>
                        <IconButton color="secondary" onClick={() => this.handleRemoveOption(option)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>

                      {option.token !== undefined
                        ? (
                          <div>
                            <div
                              style={{
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '10px',
                                marginBottom: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}>
                              <p style={{ textAlign: 'justify', overflowWrap: 'break-word', maxWidth: '500px', margin: 0 }}>
                                <code>{"<!-- WebChat 2TALK start -->"}</code>
                                <br />
                                <code>{"<script>"}</code>
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <code>
                                  {"var _2talk_chatclientkey=" + JSON.stringify(option.token.substring(0, 30) + (option.token.length > 30 ? '...' : '')) + ";\n"}
                                </code>
                                <br />
                                <code>{"</script>"}</code>
                                <br />
                                <code>{"<script"}</code><br />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <code>{`src="${option.scriptname}"`}</code><br />
                                <code>{">"}</code><br />
                                <code>{"</script>"}</code><br />
                                <code>{"<!-- WebChat  2TALK end -->"}</code>
                              </p>

                              <Tooltip title={<h5>Copiar código</h5>}>
                                <IconButton aria-label="textevidence"
                                  onClick={() => this.copyCode(option.token, option.scriptname)}>
                                  <FileCopyIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                              <b>
                                <FormattedMessage id='default' />
                              </b>
                              <Radio name={option.id} checked={option.isdefault && isWebchatDefault} disabled={!isWebchatDefault} onChange={(e, value) => this.openDialogConfirmation(option.id, value)} />
                            </div>
                          </div>
                        )
                        : (
                          <div />
                        )}

                    </div>
                  ))

                )}
              </div>

            </Grid>

            <Grid container style={{ marginTop: '25px' }}>
              <Grid item xs={4} />
              <Grid item xs={4} />
              <Grid item xs={4} container alignItems="flex-end" justifyContent="flex-end">
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  Salvar
                </Button>
              </Grid>
            </Grid>

          </Grid>
        </form>

        <DialogGeneral
          dialogTitle={"Remover canal do webchat"}
          dialogSubTitle={"Você deseja remover esse canal ?"}
          open={this.state.DialogRemoveState}
          openOrCloseModalFunction={this.openOrCloseRemoveModal}
          handleConfirmation={this.handleDeleteOption}
        />

        {this.state.dialogConfirmationOpen && <DialogConfirmation
          msgButton={intl.formatMessage({ id: "confirm" })}
          msg={intl.formatMessage({ id: "webchat.channel.default" })}
          msgDisagree={intl.formatMessage({ id: "cancel" })}
          msgAgree={intl.formatMessage({ id: "confirm" })}
          handleConfirmation={e => this.handleChangeDefault()}
          handleClose={e => this.closeDialogConfirmation()}
          classes={classes}
          disabled={false}
          icon={<DeleteIcon />}
        />}
      </div>
    );
  }
}


WebchatChannel.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userSession: state.userSession,
  webchats: state.webchats
});


export default injectIntl(withRouter(connect(mapStateToProps)(withStyles(Styles)(WebchatChannel))));
WebchatChannel.contextType = AbilityContext;