import { QueuePlayNext } from '@mui/icons-material';
import { Avatar, Button, CircularProgress, Container, CssBaseline, FormControlLabel, Grid, Radio, RadioGroup, Switch, Tab, Tabs, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import 'react-quill/dist/quill.snow.css'; // ES6
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import DialogGeneral from '../../components/dialog-general';
import Footer from '../../components/footer';
import HeaderMenu from '../../components/header-menu';
import CustomizedSnackbars from '../../components/material-snackbars';
import Title from '../../components/title-name';
import { browserHistory } from '../../helpers/history';
import * as componentActions from '../../redux/actions/integrationservices';
import Api from '../../services/api';
import * as IntegrationServiceService from "../../services/integrationservice.service";
import * as IntegrationServiceHeadersService from "../../services/integrationserviceheadersservice";
import * as IntegrationServiceParamsService from "../../services/integrationserviceparams.service";
import * as IntegrationServiceRequestBodyService from "../../services/integrationservicerequestbodyservice";
import { Overlay } from '../../styles/global';
import { Styles } from '../../styles/material-styles';
import { PapiroConsole } from '../../utils/papiroConsole';
import ApiResponseDisplay from '../authentication-edit/components/api-response-display';
import EditableTable from '../authentication-registration/components/editable-table';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import EditableTableService from './components/editable-table-service';
import RequestIntegrationService from './components/request-integration-service';

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const errorFieldReturnTypes = [
  {id: 1, name: "BOOLEANO"},
  {id: 2, name: "INTEIRO"},
  {id: 3, name: "STRING"},
  {id: 3, name: "ARRAY"},
];

class IntegrationServiceEdit extends Component {

  constructor(props) {
    super(props);
    const { userSession, integrationservices } = this.props;

    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user.userrole == null) {
      Api.logoff();
    }

    var listParams = integrationservices.integrationservice.integrationserviceparams ? integrationservices.integrationservice.integrationserviceparams : []

    var renamedListParams = listParams.map(item => ({
      id: item.id ? item.id : 0,
      name: item.name,
      value: item.defaultvalue,
      isPathParam: item?.ispathparam ?? false,
      isEditable: false,
      createdInSystem: true,
      integrationserviceparamsrelation: item.integrationserviceparamsrelation
    }));
    renamedListParams.push({ id: renamedListParams && renamedListParams.length > 0 ? renamedListParams.length + 1 : 1, name: '', value: '', isEditable: true });


    var listHeaders = integrationservices.integrationservice.integrationserviceheaders ? integrationservices.integrationservice.integrationserviceheaders : []
    var renamedListHeaders = listHeaders.map(item => ({
      id: item.id ? item.id : 0,
      name: item.name,
      value: item.value,
      isEditable: false,
      createdInSystem: true
    }));
    renamedListHeaders.push({ id: renamedListHeaders && renamedListHeaders.length > 0 ? renamedListHeaders.length + 1 : 1, name: '', value: '', isEditable: true });

    var listRequestBody = integrationservices.integrationservice.integrationservicerequestbody ? integrationservices.integrationservice.integrationservicerequestbody : []
    var jsonTextItem = listRequestBody.find(item => item.type == 3);
    const jsonValue = jsonTextItem ? jsonTextItem.value : '';

    var renamedListRequestBodyFormData = listRequestBody.filter(item => item.type == 2).map(item => ({
      id: item.id ? item.id : 0,
      name: item.name,
      value: item.defaultvalue,
      isEditable: false,
      createdInSystem: true,
      integrationservicerequestbodyrelation: item.integrationservicerequestbodyrelation
    }));

    renamedListRequestBodyFormData.push({ id: renamedListRequestBodyFormData && renamedListRequestBodyFormData.length > 0 ? renamedListRequestBodyFormData.length + 1 : 1, name: '', value: '', isEditable: true });

    //elementos de serviço que poderão ser alteados
    this.initialState = {
      updatedat: moment().format('YYYY/MM/DD HH:mm:ss'),
      updateduser: userSession.user.id,
      id: integrationservices.integrationservice.id,
      name: integrationservices.integrationservice.name,
      url: integrationservices.integrationservice.url,
      method: null,
      methodAuth: null,
      integrationAuth: null,
      requestBodyType: `${integrationservices.integrationservice.requestbodytype}`,
      objectName: integrationservices.integrationservice.objectname,
      nameErrorField: integrationservices.integrationservice.nameerrorfield,
      valueErrorField: integrationservices.integrationservice.valueerrorfield,
      messageError: integrationservices.integrationservice.messageerror,
      nameFieldDisplay: integrationservices.integrationservice.namefielddisplay,
      variableIdFieldDisplay: null,
      nameFieldValue: integrationservices.integrationservice.namefieldvalue,
      variableIdFieldValue: null,
      errorFieldReturnType: null,
      errorDataFieldName: '',
      notFoundErrorMessage: ''
    };

    this.state = {
      item: this.initialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',

      //seleção teste de integração
      integrationAuthTest: null,

      //retorno teste de autenticação 
      statusIntegrationAuthTest: null,
      contentIntegrationAuthTest: null,

      //retorno teste de serviço
      contentIntegrationServiceTest: null,
      statusIntegrationServiceTest: null,

      //listagem
      methodList: [],
      integrationAuthList: [],
      methodAuthList: [],
      associationList: [],

      //tab
      tabIndex: 0,

      //parâmetros
      paramRows: renamedListParams,
      headerRows: renamedListHeaders,
      formDataRows: renamedListRequestBodyFormData,
      jsonValue: jsonValue,
      jsonError: '',

      //dialogs
      DialogRemoveParamState: false,
      DialogRemoveHeaderState: false,
      DialogRemoveFormDataState: false,

      //seleções teste de requisição
      methodAuth: { id: 0, name: '' },
      token: '',
      basicAuthUser: '',
      basicAuthPassword: '',


    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.closeRemoveParamsModal = this.closeRemoveParamsModal.bind(this);
    this.testAuthentication = this.testAuthentication.bind(this);
    this.setLoading = this.setLoading.bind(this);
  }


  async componentDidMount() {
    const { integrationservices } = this.props;

    this.setState({ loading: true })
    await this.getData();
    await this.testAuthentication(null,integrationservices.integrationservice.integrationauthid)

    this.setState({ loading: false })
  };

  async getData() {
    const { integrationservices } = this.props;

    const result = await IntegrationServiceService.getData();

    if (result.success) {
      var data = result.data

      var selectedMethod = data.methodintegration && data.methodintegration.length && data.methodintegration.length > 0 ? data.methodintegration.find(c => c.id == integrationservices.integrationservice.method) : null

      var selectedIntegrationAuth = data.integrationauth && data.integrationauth.length && data.integrationauth.length > 0 ? data.integrationauth.find(c => c.id == integrationservices.integrationservice.integrationauthid) : null

      var selectedMethodAuth = data.authenticationmethodintegration && data.authenticationmethodintegration.length && data.authenticationmethodintegration.length > 0 ? data.authenticationmethodintegration.find(c => c.id == integrationservices.integrationservice.methodauth) : null;

      var paramRows = this.state.paramRows;
      var formDataRows = this.state.formDataRows;
      paramRows.forEach(element => {
        if (element.integrationserviceparamsrelation) {

          var selectedElement = data.associationlist && data.associationlist.length && data.associationlist.length > 0 ? data.associationlist.find(c => c.relationid == element.integrationserviceparamsrelation.relationid && c.type == element.integrationserviceparamsrelation.type) : null;
          element.integrationserviceparamsrelation = selectedElement;
        }
      })

      formDataRows.forEach(element => {
        if (element.integrationservicerequestbodyrelation) {

          var selectedElement = data.associationlist && data.associationlist.length && data.associationlist.length > 0 ? data.associationlist.find(c => c.relationid == element.integrationservicerequestbodyrelation.relationid && c.type == element.integrationservicerequestbodyrelation.type) : null;
          element.integrationservicerequestbodyrelation = selectedElement;
        }
      });

      var selectedVariableFieldValue = null;
      var selectedVariableFieldDisplay = null;

      if (data?.associationlist && data?.associationlist?.length > 0) {
        selectedVariableFieldDisplay = data.associationlist.find(c => c.relationid === integrationservices.integrationservice.variableidfielddisplay && c.type === "variable");

        selectedVariableFieldValue = data.associationlist.find(c => c.relationid === integrationservices.integrationservice.variableidfieldvalue && c.type === "variable");
      }

      this.setState(prevState => ({
        item: {
          ...prevState.item,
          method: selectedMethod,
          integrationAuth: selectedIntegrationAuth,
          methodAuth: selectedMethodAuth,
          variableIdFieldDisplay: selectedVariableFieldDisplay,
          variableIdFieldValue: selectedVariableFieldValue,
          errorFieldReturnType: errorFieldReturnTypes.find(item => item.id === integrationservices.integrationservice.errorfieldreturntype) ?? null,
          errorDataFieldName: integrationservices.integrationservice.errordatafieldname,
          notFoundErrorMessage: integrationservices.integrationservice.notfounderrormessage
        },
        //teste de autenticação padrão
        integrationAuthTest: selectedIntegrationAuth,
        associationList: data.associationlist,
        integrationAuthList: data.integrationauth,
        methodList: data.methodintegration,//,
        methodAuthList: data.authenticationmethodintegration,
        methodAuth: data?.authenticationmethodintegration && data?.authenticationmethodintegration?.length > 0 ? data.authenticationmethodintegration[0] : null,
        paramRows: paramRows,
        formDataRows: formDataRows,
      }))
    }
    else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }

  }

  handleInputChange = e => {
    const name = e.target.name
    const value = e.target.value

    if (name == 'token') {
      this.setState({
        [name]: value
      })
    }
    else {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: value
        }
      }))
    }


  }

  validations(data) {
    PapiroConsole.log("entrei aqui")

    const intl = this.props.intl;
    PapiroConsole.log("data")
    PapiroConsole.log(data)

    Object.keys(data).forEach(element => {
      PapiroConsole.log(element)
      if (element == 'name') {
        if (data[element] === null || data[element] == '') {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "É necessário o preenchimento do campo 'Nome'",
          })

          return false;
        }
      }
      if (element == 'url') {
        if (data[element] === null || data[element] == '') {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "É necessário o preenchimento do campo 'Url'",
          })
          return false;

        }
      }
      if (element == 'method') {

        if (data[element] === null || data[element].id == 0) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "É necessário o preenchimento do campo 'Método de chamada'",
          })

          return false;
        }

      }
      if (element == 'integrationAuth') {

        if (data[element] === null || data[element].id == 0) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "É necessário o preenchimento do campo 'Autenticação'",
          })

          return false;
        }

      }



    });

    return true;


  }

  testAuthentication = async (e,id) => {

    PapiroConsole.log("entrei aqui no testAuthentication")
    PapiroConsole.log(this.state.integrationAuthTest)
    

    id = id ? id : this.state.integrationAuthTest ? this.state.integrationAuthTest.id : null
    PapiroConsole.log(id)

    if (id == 0 || id == null) {
      PapiroConsole.log("entrei aqui no testAuthentication 2")
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "Ocorreu um erro ao fazer o teste de integração",
      })

    }
    else {
      this.setState({ loading: true })

      const formData = {
        integrationauthid: id
      }

      PapiroConsole.log("entrei aqui no testAuthentication 3")
      PapiroConsole.log(id)

      var result = await IntegrationServiceService.testAuthentication(formData)
      PapiroConsole.log("result aqui 123")
      PapiroConsole.log(result)
      if (result && result.data && result.success) {
        try {

          const intl = this.props.intl;
          if (result.data.success) {
            //const jsonData = JSON.parse(result.data.content);
            // var content = result.data.content
            const jsonData = JSON.parse(result.data.content);
            const accessTokenFieldName = result.data && result.data.accesstokenfieldname ? result.data.accesstokenfieldname : ''
            var content = jsonData.data && jsonData.data[accessTokenFieldName] != null ? jsonData.data[accessTokenFieldName] : jsonData[accessTokenFieldName] != null ? jsonData[accessTokenFieldName] : result.data.content


            this.setState({
              loading: false,
              statusIntegrationAuthTest: `Status Code: ${result.data.statuscode}`,
              contentIntegrationAuthTest: content,
              token: content,
            })
          }
          else {
            this.setState({
              loading: false,
              statusIntegrationAuthTest: `Status Code: ${result.data.statuscode}`,
              contentIntegrationAuthTest: result.data.content ? result.data.content : result.data.reasonphrase
            })

          }

        } catch (error) {

          const intl = this.props.intl;
          this.setState({
            loading: false,
            statusIntegrationAuthTest: `Status Code: ${result.data.statuscode}`,
            contentIntegrationAuthTest: result.data.content,

          })
        }

      } else {
        PapiroConsole.log("result error")
        PapiroConsole.log(result)
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }),
        })
        Api.kickoff(result)
      }


    }




  }

  testService = async e => {

    e.preventDefault()

    this.setState({ loading: true })

    //const formData = Object.assign({}, this.state.item);

    const data = {
      Id: this.state.item.id,
      RequestBodyType: this.state.item.requestBodyType,
      method: this.state.item.method.id,
      url: this.state.item.url,
      jsonValue: this.state.jsonValue,
      methodAuth: this.state.methodAuth.id,
      basicAuthUser: this.state.basicAuthUser,
      basicAuthPassword: this.state.basicAuthPassword,
      token: this.state.token


    }

    var filteredListParams = []
    var filteredListHeaders = []
    var filteredListFormData = []

    if (this.state.paramRows) {
      filteredListParams = this.state.paramRows.filter(item => !item.isEditable);
    }
    if (this.state.headerRows) {
      filteredListHeaders = this.state.headerRows.filter(item => !item.isEditable);
    }

    if (this.state.formDataRows) {
      filteredListFormData = this.state.formDataRows.filter(item => !item.isEditable);
    }

    data.ParamRows = filteredListParams;
    data.HeaderRows = filteredListHeaders;
    data.BodyRequestRows = filteredListFormData;




    var result = await IntegrationServiceService.testService(data)
    PapiroConsole.log("result aqui 123")
    PapiroConsole.log(result)
    if (result && result.data && result.success) {
      try {

        const intl = this.props.intl;
        if (result.data.success) {
          const jsonData = JSON.parse(result.data.content);
          var content = JSON.stringify(jsonData, null, 2)


          this.setState({
            loading: false,
            statusIntegrationServiceTest: `Status Code: ${result.data.statuscode}`,
            contentIntegrationServiceTest: content
          })
        }
        else {
          this.setState({
            loading: false,
            statusIntegrationServiceTest: `Status Code: ${result.data.statuscode}`,
            contentIntegrationServiceTest: result.data.content ? result.data.content : result.data.reasonphrase
          })

        }

      } catch (error) {

        const intl = this.props.intl;
        this.setState({
          loading: false,
          statusIntegrationServiceTest: `Status Code: ${result.data.statuscode}`,
          contentIntegrationServiceTest: result.data.content,

        })
      }

    } else {
      PapiroConsole.log("result error")
      PapiroConsole.log(result)
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }),
      })
      Api.kickoff(result)
    }



  }

  setLoading = (value) => {
    this.setState({ loading: value })
  }

  handleSubmit = async e => {
    e.preventDefault()

    this.setState({ loading: true })

    const formData = Object.assign({}, this.state.item);

    if (this.validations(formData)) {

      var filteredListParams = []
      var filteredListHeaders = []
      var filteredListFormData = []

      if (this.state.paramRows) {
        filteredListParams = this.state.paramRows.filter(item => !item.isEditable);
      }
      if (this.state.headerRows) {
        filteredListHeaders = this.state.headerRows.filter(item => !item.isEditable);
      }

      if (this.state.formDataRows) {
        filteredListFormData = this.state.formDataRows.filter(item => !item.isEditable);
      }

      formData.ParamRows = filteredListParams;
      formData.HeaderRows = filteredListHeaders;
      formData.BodyRequestRows = filteredListFormData;
      formData.jsonValue = this.state.jsonValue != '' ? this.state.jsonValue : null;

      formData.method = formData.method.id

      formData.methodAuth = formData.methodAuth.id
      formData.variableIdFieldDisplay = formData.variableIdFieldDisplay.relationid
      formData.variableIdFieldValue = formData.variableIdFieldValue.relationid
      formData.errorFieldReturnType = formData.errorFieldReturnType.id

      var result = await IntegrationServiceService.updateItem(formData)
      if (result && result.success) {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: "Registro atualizado com sucesso",

        })

        this.props.addCurrent(result.data)


      } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
      }

    }
  };

  closeNotification() {
    this.setState({ openNotification: false })
  }

  changeValues(stateName, value, text = '') {
    if (stateName == "integrationAuthTest") {
      this.setState({ [stateName]: value })
    }
    else if (stateName == 'methodAuth') {
      this.setState({
        [stateName]: value
      })
    }
    else if (stateName == '_methodAuth') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          methodAuth: value
        }
      }))
    }
    else {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]: value
        }
      }))
    }
  }

  handleAddRow = async (type, id) => {
    PapiroConsole.log("id aqui")
    PapiroConsole.log(id)

    const row = this.state[type].find(row => row.id === id);

    this.setState({ loading: true });

    if (row.name) {

      var formData = {
        name: row.name,
        value: row.value ?? "",
        integrationserviceid: this.state.item.id,
        ...(type === "paramRows" ? {ispathparam: row?.isPathParam ?? false} : {})
      }

      var result;

      switch (type) {
        case "paramRows":
          if (row.integrationserviceparamsrelation != null) {
            formData.integrationserviceparamsrelation = row.integrationserviceparamsrelation
          }
          result = result = await IntegrationServiceParamsService.addItem(formData)
          break;
        case "headerRows":
          result = result = await IntegrationServiceHeadersService.addItem(formData)
          break;
        case "formDataRows":
          if (row.integrationservicerequestbodyrelation != null) {
            formData.integrationservicerequestbodyrelation = row.integrationservicerequestbodyrelation
          }
          result = result = await IntegrationServiceRequestBodyService.addItem(formData)
        default:
          break;
      }

      if (result && result.success) {
        this.setState(prevState => ({
          [type]: prevState[type].map(row => (row.id === id ? { ...row, createdInSystem: true, id: result.data.id, isEditable: false } : row)).concat({ id: Math.max(...prevState[type].map(r => r.id)) + 1, name: '', value: '', isEditable: true }),
          loading: false
        }));

        this.props.addCurrent(result.data)
      } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
      }
    }

    this.setState({ loading: false });
  }

  handleTabChange = (e, newValue) => {
    this.setState({ tabIndex: newValue });
  };

  handleRequestBodyTypeChange = (e) => {
    this.setState(prev => ({item: { ...prev.item, requestBodyType: e.target.value, jsonError: '' }}));
  };

  handleInputChangeTable = (type, field, value, id) => {

    PapiroConsole.log("type aqui")
    PapiroConsole.log(type)
    PapiroConsole.log(id)
    PapiroConsole.log(field)
    PapiroConsole.log(value)


    this.setState(prevState => ({
      [type]: prevState[type].map(row => (row.id === id ? { ...row, [field]: value } : row))
    }));

  };

  handleChangeAutoCompleteTable = (type, value, id, field) => {

    PapiroConsole.log("handleChangeAutoCompleteTable")

    PapiroConsole.log(id)
    PapiroConsole.log(field)
    PapiroConsole.log(value)



    this.setState(prevState => ({
      [type]: prevState[type].map(row => (row.id === id ? { ...row, [field]: value } : row))
    }));

  };

  validateJson = (value) => {
    try {
      JSON.parse(value);
      this.setState({ jsonError: '' }); // Se o JSON for válido, limpa a mensagem de erro
    } catch (error) {
      this.setState({ jsonError: 'JSON inválido' }); // Se o JSON for inválido, define a mensagem de erro
    }
  };

  handleJsonValueChange = (e) => {
    const { value } = e.target;
    this.setState({ jsonValue: value });
    this.validateJson(value);
  };

  deleteParam = () => {

    this.setState({ loading: true });

    Api.delete(`/integrationserviceparams/${this.state.rowIdParamToRemove}`)
      .then(result => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState(prevState => ({
            paramRows: prevState["paramRows"].filter(row => row.id !== this.state.rowIdParamToRemove),
            rowIdParamToRemove: 0,
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
          }));

          this.props.addCurrent(result.data.data)
          // this.setState(prevState => ({
          //   rows: prevState.rows.filter(row => row.id !== this.state.rowIdParamToRemove),

          // }),

          this.closeRemoveParamsModal()
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }

      })
      .catch(err => {

        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(err);
      });
  }

  handleDeleteParam = (type, rowItem) => {

    // caso o parâmetro ja exista na base
    if (rowItem.createdInSystem == true) {
      this.setState({ DialogRemoveParamState: true, rowIdParamToRemove: rowItem.id })
    }
  };

  closeRemoveParamsModal = () => {

    this.setState({ DialogRemoveParamState: false, rowIdParamToRemove: 0 })

  };

  deleteHeader = () => {

    this.setState({ loading: true });

    Api.delete(`/integrationserviceheaders/${this.state.rowIdHeaderToRemove}`)
      .then(result => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState(prevState => ({
            headerRows: prevState["headerRows"].filter(row => row.id !== this.state.rowIdHeaderToRemove),
            rowIdHeaderToRemove: 0,
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
          }));
          // this.setState(prevState => ({
          //   rows: prevState.rows.filter(row => row.id !== this.state.rowIdParamToRemove),

          // }),
          this.props.addCurrent(result.data.data)

          this.closeRemoveHeadersModal()
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }

      })
      .catch(err => {

        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(err);
      });
  }

  handleDeleteHeader = (type, rowItem) => {

    // caso o parâmetro ja exista na base
    if (rowItem.createdInSystem == true) {
      this.setState({ DialogRemoveHeaderState: true, rowIdHeaderToRemove: rowItem.id })
    }
  };

  closeRemoveHeadersModal = () => {

    this.setState({ DialogRemoveHeaderState: false, rowIdHeaderToRemove: 0 })

  };

  deleteFormData = () => {

    this.setState({ loading: true });

    PapiroConsole.log("this.state.rowIdFormDataToRemove 2")
    PapiroConsole.log(this.state.rowIdFormDataToRemove)

    Api.delete(`/integrationservicerequestbody/${this.state.rowIdFormDataToRemove}`)
      .then(result => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState(prevState => ({
            formDataRows: prevState["formDataRows"].filter(row => row.id !== this.state.rowIdFormDataToRemove),
            rowIdFormDataToRemove: 0,
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
          }));

          this.props.addCurrent(result.data.data)
          // this.setState(prevState => ({
          //   rows: prevState.rows.filter(row => row.id !== this.state.rowIdParamToRemove),

          // }),

          this.closeRemoveFormDatasModal()
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }

      })
      .catch(err => {

        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(err);
      });
  }

  handleDeleteFormData = (type, rowItem) => {

    PapiroConsole.log("rowIdFormDataToRemove 1 ")
    PapiroConsole.log(rowItem)

    // caso o parâmetro ja exista na base
    if (rowItem.createdInSystem == true) {
      this.setState({ DialogRemoveFormDataState: true, rowIdFormDataToRemove: rowItem.id })
    }
  };

  closeRemoveFormDatasModal = () => {

    this.setState({ DialogRemoveFormDataState: false, rowIdFormDataToRemove: 0 })

  };

  handleChangeSwitch(event) {
    const stateName = event.target.name;
    const value = event.target.checked;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
  }


  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
    const { rows } = this.state;
    PapiroConsole.log("this.state")
    PapiroConsole.log(this.state)

    const { tabIndex, paramRows, headerRows, formDataRows, jsonValue, jsonError } = this.state;
    const { requestBodyType } = this.state.item;



    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}>
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar style={{ backgroundColor: '#3f51b4' }}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5' style={{ marginTop: '10px' }}>
                {<FormattedMessage id="edit" />} {<FormattedMessage id="integration.service" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Accordion defaultExpanded>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{
                        borderLeft: '5px solid #3f51b4',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        padding: '10px 20px',
                        backgroundColor: '#f9f9f9',
                      }}>
                        <Typography variant="h6">Teste de autenticação</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ marginTop: '20px' }}>
                        <Grid item xs={12} sm={12}>
                          <SimpleAutoComplete label={intl.formatMessage({ id: "authentication" })} options={this.state.integrationAuthList && this.state.integrationAuthList.length && this.state.integrationAuthList.length > 0 ? this.state.integrationAuthList : []} name='integrationAuthTest' stateName='integrationAuthTest' changeSelect={this.changeValues} selected={this.state.integrationAuthTest} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {this.state.contentIntegrationAuthTest && this.state.statusIntegrationAuthTest &&
                            <ApiResponseDisplay status={this.state.statusIntegrationAuthTest} content={this.state.contentIntegrationAuthTest} />
                          }
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end',  marginTop: '20px'  }} >
                          <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                            <Grid item xs={12} sm={4}    >
                              <Button
                                onClick={this.testAuthentication}
                                fullWidth
                                variant='contained'
                                color='primary'
                              >
                                {<FormattedMessage id="Testar" />}
                              </Button>

                            </Grid>

                          </Grid>
                        </Grid>

                      </AccordionDetails>
                    </Accordion>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{
                        borderLeft: '5px solid #3f51b4',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        padding: '10px 20px',
                        backgroundColor: '#f9f9f9',
                      }}>
                        <Typography variant="h6">Dados do serviço</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ marginTop: '20px' }}>
                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <SimpleAutoComplete 
                            label={intl.formatMessage({ id: "authentication" })} 
                            options={this.state.integrationAuthList && this.state.integrationAuthList.length && this.state.integrationAuthList.length > 0 ? this.state.integrationAuthList : []} 
                            stateName='integrationAuth' 
                            name='integrationAuth'
                            changeSelect={this.changeValues} 
                            selected={this.state.item.integrationAuth} required 
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <SimpleAutoComplete 
                            label={intl.formatMessage({ id: "authentication.method" })} 
                            options={this.state.methodAuthList} 
                            stateName='_methodAuth' 
                            changeSelect={this.changeValues} 
                            selected={this.state.item.methodAuth} 
                            name='_methodAuth'
                            required />
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "name" })} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "url" })} autoComplete='fname' variant='outlined' name='url' onChange={this.handleInputChange} value={item.url} required selected={this.state.item.url} />
                        </Grid>


                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <SimpleAutoComplete label={intl.formatMessage({ id: "call.method" })} options={this.state.methodList && this.state.methodList.length && this.state.methodList.length > 0 ? this.state.methodList : []} stateName='method' name='method' changeSelect={this.changeValues} selected={this.state.item.method} required />
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <TextField inputProps={{ maxLength: 255 }} fullWidth label="Nome do objeto origem" autoComplete='fname' variant='outlined' name='objectName' onChange={this.handleInputChange} value={item.objectName} />
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <TextField inputProps={{ maxLength: 255 }} fullWidth label="Nome do campo indicativo de erro" autoComplete='fname' variant='outlined' name='nameErrorField' onChange={this.handleInputChange} value={item.nameErrorField} />
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <SimpleAutoComplete 
                            label="Tipo de retorno do campo indicativo de erro"
                            options={errorFieldReturnTypes} 
                            stateName='errorFieldReturnType' 
                            changeSelect={this.changeValues} 
                            selected={this.state.item.errorFieldReturnType} 
                            required 
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <TextField 
                            inputProps={{ maxLength: 255 }} 
                            fullWidth 
                            label="Valor do campo de erro que indica que o erro ocorreu" 
                            autoComplete='fname' 
                            variant='outlined' 
                            name='valueErrorField' 
                            onChange={this.handleInputChange} 
                            value={item.valueErrorField} 
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} >
                          <TextField inputProps={{ maxLength: 255 }} fullWidth label="Nome do campo que terá os dados de erro" autoComplete='fname' variant='outlined' name='errorDataFieldName' onChange={this.handleInputChange} value={item.errorDataFieldName} style={{ marginBottom: '15px' }} />
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <TextField multiline rows={4} fullWidth label="Mensagem que será enviada, em caso de erro" variant='outlined' name='messageError' onChange={this.handleInputChange} value={item.messageError} />
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <TextField 
                            multiline 
                            rows={4} 
                            fullWidth 
                            label="Mensagem que será enviada, em caso do objeto de retorno não ser encontrado" 
                            variant='outlined' 
                            name='notFoundErrorMessage' 
                            onChange={this.handleInputChange} 
                            value={item.notFoundErrorMessage} 
                          />
                        </Grid> 

                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "Nome do campo de valor" })} variant='outlined' name='nameFieldValue' onChange={this.handleInputChange} value={item.nameFieldValue} required />
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <SimpleAutoComplete 
                            label={intl.formatMessage({ id: "Associação campo de valor" })} 
                            options={this.state.associationList?.filter(item => item.type === "variable")} 
                            stateName='variableIdFieldValue' 
                            changeSelect={this.changeValues} 
                            selected={this.state.item.variableIdFieldValue} 
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "Nome do campo de exibição" })} variant='outlined' name='nameFieldDisplay' onChange={this.handleInputChange} value={item.nameFieldDisplay} required />
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <SimpleAutoComplete 
                            label={intl.formatMessage({ id: "Associação campo de exibição" })} 
                            options={this.state.associationList?.filter(item => item.type === "variable")} 
                            stateName='variableIdFieldDisplay' 
                            name='variableIdFieldDisplay'
                            changeSelect={this.changeValues} 
                            selected={this.state.item.variableIdFieldDisplay} 
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <Switch
                            value={this.state.item.isArrayObjectType}
                            checked={this.state.item.isArrayObjectType}
                            name="isArrayObjectType"
                            onChange={(e) => this.handleChangeSwitch(e)}
                          />
                          <FormattedMessage id="O retorno é um array ?" />
                        </Grid>  



                        <Grid item xs={12} sm={12}>
                          <Tabs style={{ marginTop: '10px' }} value={tabIndex} onChange={this.handleTabChange}>
                            <Tab label="Parâmetros de consulta" />
                            <Tab label="Cabeçalho" />
                            <Tab label="Corpo da Requisição" />
                          </Tabs>

                          {tabIndex === 0 && (
                            <EditableTableService rows={paramRows} handleDeleteRow={this.handleDeleteParam} handleAddRow={this.handleAddRow} handleChangeAutoCompleteTable={this.handleChangeAutoCompleteTable} handleInputChangeTable={this.handleInputChangeTable} type="paramRows" associationList={this.state.associationList && this.state.associationList.length && this.state.associationList.length > 0 ? this.state.associationList : []} />
                          )}

                          {tabIndex === 1 && (
                            <EditableTable rows={headerRows} handleDeleteRow={this.handleDeleteHeader} handleAddRow={this.handleAddRow} handleInputChangeTable={this.handleInputChangeTable} type="headerRows" />
                          )}

                          {tabIndex === 2 && (

                            <Grid item xs={12}>
                              <RadioGroup
                                value={requestBodyType}
                                onChange={this.handleRequestBodyTypeChange}
                                sx={{ display: 'flex', flexDirection: 'row' }} // Exibir horizontalmente
                              >
                                <FormControlLabel value="1" control={<Radio />} label="Nenhum" />
                                <FormControlLabel value="2" control={<Radio />} label="Form-data" />
                                <FormControlLabel value="3" control={<Radio />} label="JSON" />
                              </RadioGroup>




                              {requestBodyType === '2' && (
                                <EditableTableService handleDeleteRow={this.handleDeleteFormData} handleAddRow={this.handleAddRow} rows={formDataRows} handleInputChangeTable={this.handleInputChangeTable} type="formDataRows" associationList={this.state.associationList && this.state.associationList.length && this.state.associationList.length > 0 ? this.state.associationList : []} handleChangeAutoCompleteTable={this.handleChangeAutoCompleteTable} />
                              )}

                              {requestBodyType === '3' && (
                                <div>
                                  <StyledTextField
                                    multiline
                                    rows={10}
                                    value={jsonValue}
                                    onChange={this.handleJsonValueChange}
                                    placeholder="Digite seu JSON aqui"
                                    error={!!jsonError} // Define o estado de erro
                                    helperText={jsonError} // Exibe a mensagem de erro
                                  />
                                </div>
                              )}

                            </Grid>

                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '25px' }}>
                          <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>

                            <Grid item xs={12} sm={4}    >
                              <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='primary'
                                className={classes.submit} >
                                {<FormattedMessage id="save" />}
                              </Button>

                            </Grid>

                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>


                  <Grid item xs={12} sm={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{
                        borderLeft: '5px solid #3f51b4',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        padding: '10px 20px',
                        backgroundColor: '#f9f9f9',
                      }}>
                        <Typography variant="h6">Testar requisição - Serviço</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ marginTop: '20px' }}>
                        <RequestIntegrationService
                          token={this.state.token}
                          integrationServiceId={this.state?.item?.id}
                        />

                        {/*
                        <div>
                          <p>URL da requisição</p>
                          <div>

                          </div>
                        </div>

                        <div>
                          <p>Parâmetros da requisição</p>
                          <div>

                          </div>
                        </div>

                        <div>
                          <p>Cabeçalho da requisição</p>
                          <div>

                          </div>
                        </div>

                        <div>
                          <p>Corpo da requisição</p>
                          <div>

                          </div>
                        </div>

                        <p>Método de autenticação</p>

                        <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                          <SimpleAutoComplete label={intl.formatMessage({ id: "authentication.method" })} options={this.state.methodAuthList && this.state.methodAuthList.length && this.state.methodAuthList.length > 0 ? this.state.methodAuthList : []} name="methodAuth" stateName='methodAuth' changeSelect={this.changeValues} selected={this.state.item.methodAuth} />
                        </Grid>
                        {this.state.methodAuth && this.state.methodAuth.id == 2 &&

                          <>
                            <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                              <TextField inputProps={{ maxLength: 255 }} fullWidth label="Usuário da autenticação básica" variant='outlined' name='basicAuthUser' onChange={this.handleInputChange} value={item.basicAuthUser} required={this.state.item.methodAuth && this.state.item.methodAuth.id == 2 ? true : false} />
                            </Grid>

                            <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                              <TextField inputProps={{ maxLength: 255 }} fullWidth label="Senha da autenticação básica" variant='outlined' name='basicAuthPassword' onChange={this.handleInputChange} value={item.basicAuthPassword} required={this.state.item.methodAuth && this.state.item.methodAuth.id == 2 ? true : false} />
                            </Grid>

                          </>


                        }

                        {this.state.methodAuth && this.state.methodAuth.id == 1 &&

                          <>
                            <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                              <TextField fullWidth label="Token" multiline
                                rows={10} variant='outlined' name='token' onChange={this.handleInputChange} value={this.state.token} />
                            </Grid>
                          </>

                        }

                        <Grid item xs={12} sm={12}>
                          {this.state.contentIntegrationServiceTest && this.state.statusIntegrationServiceTest &&
                            <ApiResponseDisplay status={this.state.statusIntegrationServiceTest} content={this.state.contentIntegrationServiceTest} />
                          }
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                            <Grid item xs={12} sm={4}    >
                              <Button
                                onClick={this.testService}
                                fullWidth
                                variant='contained'
                                color='primary'
                              >
                                {<FormattedMessage id="Testar" />}
                              </Button>

                            </Grid>

                          </Grid>
                        </Grid>

                        */}

                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </form>
            </div>

            <DialogGeneral
              dialogTitle="Remoção de parâmetro"
              dialogSubTitle="Você realmente deseja remover este parâmetro ?"
              open={this.state.DialogRemoveParamState}
              openOrCloseModalFunction={this.closeRemoveParamsModal}
              handleConfirmation={this.deleteParam}
            />
            <DialogGeneral
              dialogTitle="Remoção de cabeçalho"
              dialogSubTitle="Você realmente deseja remover este cabeçalho ?"
              open={this.state.DialogRemoveHeaderState}
              openOrCloseModalFunction={this.closeRemoveHeadersModal}
              handleConfirmation={this.deleteHeader}
            />
            <DialogGeneral
              dialogTitle="Remoção de dados"
              dialogSubTitle="Você realmente deseja remover este dado selecionado ?"
              open={this.state.DialogRemoveFormDataState}
              openOrCloseModalFunction={this.closeRemoveFormDatasModal}
              handleConfirmation={this.deleteFormData}
            />



            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}
            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />



          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  integrationservices: state.integrationservices
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(IntegrationServiceEdit)));
