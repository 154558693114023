import { combineReducers } from 'redux';
import accompaniments from './accompaniments';
import actionsHeaderMenu from './actions-header-menu';
import addresses from './addresses';
import assignedcalleds from './assignedcalleds';
import calledclassifications from './calledclassifications';
import calledflows from './calledflows';
import calledorganizationmaterials from './calledorganizationmaterials';
import calleds from './calleds';
import calledstatuses from './calledstatuses';
import categories from './categories';
import categoryorganizations from './categoryorganizations';
import configs from './configs';
import groups from './groups';
import headerMenu from './header-menu';
import organization from './organization';
import organizationcalendars from './organizationcalendars';
import organizationfields from './organizationfields';
import organizations from './organizations';
import organizationsettings from './organizationsettings';
import originrequests from './originrequests';
import permissions from './permissions';
import roles from './roles';
import segments from './segments';
import slas from './slas';
import statuses from './statuses';
import techinicalteamorganizationcategories from './techinicalteamorganizationcategories';
import techinicalteamorganizations from './techinicalteamorganizations';
import techinicalteams from './techinicalteams';
import techinicalteamusers from './techinicalteamusers';
import types from './types';
import userOrganizations from './user-organization';
import userSession from './user-session';
import users from './users';

import calledflowstatuses from './calledflowstatuses';

import distributionstrategies from './distributionstrategies';

import organizationcategories from './organizationcategories';

import organizationslas from './organizationslas';

import organizationslacalleds from './organizationslacalleds';

import scheduleworkhours from './scheduleworkhours';

import slacounttypes from './slacounttypes';

import calledvideocalls from './calledvideocalls';
import holidays from './holidays';
import materials from './materials';
import workhours from './workhours';

import organizationdepartaments from './organizationdepartaments';

import organizationmaterials from './organizationmaterials';

import organizationpatrimonies from './organizationpatrimonies';

import patrimonygroups from './patrimonygroups';

import patrimonymaterials from './patrimonymaterials';

import patrimonysubgroups from './patrimonysubgroups';

import organizationaccountemails from './organizationaccountemails';

import relationshipcalled from './relationshipcalled';

import knowledgebases from './knowledgebases';
import organizationchecklists from './organizationchecklists';

import calledorganizationchecklists from './calledorganizationchecklists';

import calledorganizationfields from './calledorganizationfields';

import additionalcalledcosts from './additionalcalledcosts';

import reasons from './reasons';

import activitys from './activitys';
import additionalcalledcostitemreasons from './additionalcalledcostitemreasons';
import additionalcalledcostitems from './additionalcalledcostitems';
import additionalcalledcostitemtypes from './additionalcalledcostitemtypes';
import calledclosingstatuses from './calledclosingstatuses';
import calledlistdatas from './calledlistdatas';
import calledreportconfigs from './calledreportconfigs';
import calledservicesettings from './calledservicesettings';
import calledservicesettingscalledfields from './calledservicesettingscalledfields';
import calledservicesettingsfileuploads from './calledservicesettingsfileuploads';
import calledservicesettingsfileuploadvalidations from './calledservicesettingsfileuploadvalidations';
import calledservicesettingsorganizationfields from './calledservicesettingsorganizationfields';
import calledservicesettingsorganizationfieldvalidations from './calledservicesettingsorganizationfieldvalidations';
import calledservicesettingsvalidations from './calledservicesettingsvalidations';
import channels from './channels';
import chatbotflows from './chatbotflows';
import chatbots from './chatbots';
import chats from './chats';
import companies from './companies';
import customers from './customers';
import defaultactions from './defaultactions';
import integrationUserVariables from './integration-user-variable';
import integrationauths from './integrationauths';
import lastCalledsQueue from './last-calleds-queue';
import multihirer from './multihirer';
import npssearchs from './npssearchs';
import organizationcategoryportal from './organizationcategoryportal';
import organizationfieldreportblocks from './organizationfieldreportblocks';
import params from './params';
import phoneregistrationrequests from './phoneregistrationrequests';
import priorities from './priorities';
import { googleReducer } from "./reducerGoogle";
import richtextarea from './richtextarea';
import rulesattendance from './rulesattendance';
import satisfactionsurveys from './satisfactionsurveys';
import securitys from './security';
import shippingservicesettings from './shippingservicesettings';
import timeentries from './timeentries';
import units from './units';
import webchats from './webchats';
import whitelabelconfigs from './whitelabelconfigs';
import integrationservices from './integrationservices';

export default combineReducers({
    reasons,
    activitys,
    npssearchs,
    timeentries,
    addresses,
    groups,
    segments,
    organizations,
    types,
    headerMenu,
    actionsHeaderMenu,
    userSession,
    calleds,
    categories,
    slas,  
    users,
    calledclassifications,
    originrequests,
    organizationsettings,
    techinicalteams,
    techinicalteamusers,
    techinicalteamorganizations,
    techinicalteamorganizationcategories,   
    categoryorganizations, 
    roles,
    permissions,
    statuses,
    assignedcalleds,
    organizationcalendars,
    organizationfields,
    configs,
    defaultactions,
    workhours,
    slacounttypes,
    scheduleworkhours,
    organizationslacalleds,
    organizationslas,
    organizationcategories,
    distributionstrategies,
    calledflowstatuses,
    calledflows,    
    calledvideocalls,
    holidays,
    calledstatuses,
    patrimonysubgroups,
    patrimonymaterials,
    patrimonygroups,
    organizationpatrimonies,
    organizationmaterials,
    organizationdepartaments,
    materials,
    calledorganizationmaterials,
    organization,
    organizationaccountemails,
    relationshipcalled,
    organizationchecklists,
    knowledgebases,
    calledorganizationchecklists,
    calledorganizationfields,
    additionalcalledcosts,
    organizationcategoryportal,
    googleReducer,
    multihirer,
    priorities,
    userOrganizations,
    calledclosingstatuses,
    calledlistdatas,
    calledreportconfigs,
    additionalcalledcostitems,
    additionalcalledcostitemtypes,
    additionalcalledcostitemreasons,
    organizationfieldreportblocks,
    shippingservicesettings,
    richtextarea,
    units,
    whitelabelconfigs,
    rulesattendance,
    calledservicesettings,
    calledservicesettingsorganizationfields,
    calledservicesettingscalledfields,
    calledservicesettingsorganizationfieldvalidations,
    calledservicesettingsfileuploads,
    calledservicesettingsfileuploadvalidations,
    calledservicesettingsvalidations,
    accompaniments,
    lastCalledsQueue,
    phoneregistrationrequests,
    securitys,
    chats,
    satisfactionsurveys,
    channels,
    customers,
    companies,
    webchats,
    chatbots,
    integrationauths,
    integrationUserVariables,
    params,
    chatbotflows,
    integrationservices
});
