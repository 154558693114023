import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import CloseIcon from '@mui/icons-material/Close';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { grey } from '@mui/material/colors';
import { getOrganizations } from "../../services/called.service";
import { 
  getAllUserChatStatus,
  createUserChatStatus, 
  updateUserChatStatus 
} from "../../services/user.service";
import { PapiroConsole } from "../../utils/papiroConsole";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: grey[300],
    color: grey[800],
  },
  modal: {
    minWidth: "410px"
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute", 
    top: "13px", 
    right: "13px"
  }
});

export function AvailabilityConfigChat({ onClose, selectedValue, open, userSession, intl }) {

  const successMessage = { 
    notificationVariant: "success",
    notificationMessage: intl.formatMessage({id:"status.update.success"}),
    openNotification: true
  }
  const errorMessage = { 
    notificationVariant: "error",
    notificationMessage: intl.formatMessage({id:"status.update.fail"}),
    openNotification: true
  }

  const classes = useStyles();
  const [organizations, setOrganizations] = useState([])
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ ...errorMessage, openNotification: false });

  useEffect(() => {
    getAllOrganizations()
  }, [])

  const closeAlertMessage = () => setAlertMessage({ ...alertMessage, openNotification: false });

  const handleChange = (organization) => {
    const newOrganizations = organizations.map(item => {
      if (item.organization == organization) {
        return { ...item, status: !item.status, newStatus: true }
      }
      return item;
    });
    setOrganizations(newOrganizations);
  };

  const handleClose = () => {
    onClose(selectedValue);
    closeAlertMessage();
  };

  const formatData = (data) => {
    const formattedData = data.map(({ organizationsettings: orgSettings, ...item }) => {
      if (orgSettings && orgSettings.allowattendanceviachat) {
        return { org: item.name, organizationid: item.id}
      }
    });
    //* Remove os undefineds
    return formattedData.filter(item => item);
  }

  async function getAllOrganizations() {
    PapiroConsole.log("userSession getallorganizations")
    PapiroConsole.log(userSession)
    let orgData = []
    if(userSession && userSession.userorganizations && userSession.userorganizations.length > 0) {
      orgData = userSession.userorganizations
    } else {
      const response = await getOrganizations()
      if (response && response.success) {
        orgData = response.data
      }
    }
    PapiroConsole.log("orgData")
    PapiroConsole.log(orgData)
    if (orgData != null) {

      const userOrganizationsStatus = await getUserStatus();
      PapiroConsole.log("userOrganizationsStatus")
      PapiroConsole.log(userOrganizationsStatus)

      const organizations = formatData(orgData).map(org => {
        return pickUserStatusOfAnOrganization(org, userOrganizationsStatus);
      });
      
      setOrganizations(organizations)
    }
  }

  const pickUserStatusOfAnOrganization = ({ org, organizationid }, orgList) => {
    //* este objeto será retornado caso ainda não exista registro
    //* de status para esta organização. 
    //* O atributo new: true nos permite saber se será
    //* necessário realizar uma {intl.formatMessage({id:"request"})} POST
    let orgStatus = { organizationid, organization: org, status: false, newStatus: null, new: true };
    
    orgList.forEach(item => {
      //* Verdadeiro se este usuário ja tem status para esta organização
      if (org === item.organization) {
        orgStatus = { 
          id: item.id,
          organizationid: item.organizationid,
          organization: org,
          userid: item.userid,
          status: item.status,
          newStatus: null
        }
      }
    });
    return orgStatus
  }

  async function getUserStatus() {
    const response = await getAllUserChatStatus();
    //PapiroConsole.log("=== getUserStatus ===")
    //PapiroConsole.log(response)
    const formattedData = [];

    if(response && response.data && response.data.length > 0) {
      response.data.forEach(({ id, userid, organization, organizationid, status }) => {
        formattedData.push({ 
          id,
          userid,
          organizationid: organizationid,
          organization: organization.name,
          status: status,
          new: false
        })
      });
      return formattedData;
    } else
      return [];
  }

  async function handleCreateStatusPromise(organization) {
    const newOrgStatus = await createUserChatStatus({ 
      userid: organization.userid,
      organizationid: organization.organizationid,
      status: organization.status 
    });

    return { ...organization, id: newOrgStatus.data.id, new: false, newStatus: null  }
  }

  async function handleUpdateStatusPromise(organization) {
    await updateUserChatStatus({
      id: organization.id,
      userid: organization.userid,
      organizationid: organization.organizationid,
      status: organization.status 
    });
    
    return { ...organization, new: false, newStatus: null }
  }
  
  async function handlePromisesSubmit() {
    return Promise.all(organizations.map(async item => {
      if (item.new) {
        return handleCreateStatusPromise(item);

      } else if(item.newStatus) {
        return handleUpdateStatusPromise(item);

      } else {
        return item;
      }
    }));
  } 

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    try {
      const response = await handlePromisesSubmit();
      setOrganizations(response)
      setAlertMessage(successMessage);
      setLoading(false);
    } catch(err) {
      setAlertMessage(errorMessage);
      setLoading(false);
    }
  }

  return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <CustomizedSnackbars
          variant={alertMessage.notificationVariant}
          message={alertMessage.notificationMessage}
          isOpen={alertMessage.openNotification}
          toClose={closeAlertMessage}
        />
        {loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}
        <CloseIcon onClick={ handleClose } className={classes.closeIcon}/>
        <DialogTitle id="simple-dialog-title">{intl.formatMessage({id:"chat.change.visibility"})}</DialogTitle>
        <form >
          <List className={classes.modal}>
            {organizations.map((item) => (
              <ListItem key={item.organization}>
                  <ListItem>
                    <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        <AccountBalanceIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.organization} />
                  </ListItem>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={item.status}
                        onChange={() => handleChange(item.organization)}
                        name={ item.organization }
                        color="primary"
                      />
                    }
                    label={ item.status ? "Online" : "Offline" }
                  />
              </ListItem>
            ))}
            <ListItem style={{ alignItems: "center", justifyContent: "right" }}>
              <Button type='submit' variant='contained'color='primary' onClick={ (e) => handleSubmit(e) } >
                {intl.formatMessage({id:"save.changes"})}
              </Button>
            </ListItem>
          </List>
        </form>
      </Dialog>
  );
}

AvailabilityConfigChat.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string,
  userSession: PropTypes.object.isRequired
};