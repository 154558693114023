import { shapes } from '@joint/plus';
import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';

import Input from '../Input/Input';
import Select from '../Input/Select/Select';
import { useBaseInspector } from './useBaseInspector';

import ReactQuill from 'react-quill';
import * as ChatbotFlowService from "../../../../../../../../services/2talk/chatbot-flow.service";

interface Props {
    cell: shapes.app.Flow;
    chatbotId: string;
    chatbotFlowId: string;
}

const cellProps = {
    label: ['attrs', 'label', 'text'],
    description: ['attrs', 'description', 'text'],
    icon: ['attrs', 'icon', 'xlinkHref'],
    portLabel: ['attrs', 'portLabel', 'text'],
    flowId: ['attrs', 'flowId', 'id'],
    isTextRich: ['attrs', 'isTextRich', 'isTextRich'],
};

const FlowInspector = (props: Props): ReactElement => {

    const { cell } = props;

    const [flowsOptions, setFlowsOptions] = useState([]);

    const [label, setLabel] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [flowId, setFlowId] = useState(null);
    const [isTextRich, setIsTextRich] = useState<boolean>(false);

    const assignFormFields = useCallback((): void => {
        setLabel(cell.prop(cellProps.label));
        setDescription(cell.prop(cellProps.description));
        setFlowId(cell.prop(cellProps.flowId));
        setIsTextRich(cell.prop(cellProps.isTextRich));
    }, [cell]);

    const changeCellProp = useBaseInspector({ cell, assignFormFields });

    const fetchFlows = async () => {
        const flows = await ChatbotFlowService.getList(props.chatbotId, props.chatbotFlowId);
        setFlowsOptions(flows?.data ?? []);
    }

    useEffect(() => {
        fetchFlows();
    }, [])

    return (
        <>
            <h1>Componente selecionado</h1>

            <label>Label
                <Input type="text"
                       placeholder="Enter label"
                       value={label}
                       disabled={true}
                />
            </label>

            <div 
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    marginTop: '8px',
                    marginBottom: '8px'
                }}
            >
                <input 
                    type="checkbox" 
                    style={{
                        height: '16px',
                        width: '16px',
                        margin: '0',
                        padding: '0'
                    }} 
                    checked={isTextRich}
                    onChange={(e) => changeCellProp(cellProps.isTextRich, e?.target?.checked)}
                />
                <label
                    style={{
                        marginTop: '4px'
                    }}
                >
                    É um texto rico ?
                </label>
            </div>

            {
                isTextRich
                ? (
                    <label>Descrição
                        <ReactQuill
                            name="description"
                            style={{ height: 150, marginBottom: '82px' }}
                            fullWidth
                            variant="outlined"
                            id="descriprion"
                            value={description}
                            onChange={(value: any) => changeCellProp(cellProps.description, value)}
                        />
                    </label>
                )
                : (
                    <label>Descrição
                        <Input 
                            type="text"
                            placeholder="Enter description"
                            value={description}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => changeCellProp(cellProps.description, e.target.value)}
                        />
                    </label>
                )
            }

            <label>Fluxos
                <Select
                    value={flowId}
                    onChange={(e) => changeCellProp(cellProps.flowId, e.target.value)}
                    options={flowsOptions}
                />
            </label>
        </>
    );
};

export default FlowInspector;
